<script setup lang="ts">
import { ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import type { AddCustomerRequest, UpdatedBy } from '@/api/models/customer/AddCustomerRequest'
import type { ToastMessageOptions } from 'primevue/toast'
import type { Gender } from '@/api/models/customer/Gender'
import ApiService from '@/api/ApiService'
import CustomButton from '@/components/CustomComponent/CustomButton.vue'
import LoadingComponent from '@/components/LoadingComponent.vue'
import Storage from '@/utils/Storage'
import ValidationUtility from '@/utils/ValidationUtility'
import CryptoUtil from '@/utils/CryptoUtils'
import type { CustomerWithoutID } from '@/api/models/customer/Customer'
import type { ShareHolder } from '@/api/models/shareholder/shareholder'
import type { CreatedBy } from '@/api/models/CreatedBy'
import type { CreateAccountRequest } from '@/api/models/account/CreateAccountRequest'
import ConversionUtility from '@/utils/ConversionUtility'
import { useConfirm } from 'primevue/useconfirm'
import router from '@/router'
import type { AddCustomerResponseData } from '@/api/models/customer/AddCustomerResponse'
import type { ShareholderTransactions } from '@/api/models/shareholder/ShareholderTransactions'

const confirmDialog = useConfirm()
const toast = useToast()
const isLoading = ref(false)
const isCustomerCreated = ref<boolean>(false)
const genderOptions = ref<Gender[]>([
  { name: 'Male', code: 'male' },
  { name: 'Female', code: 'female' },
  { name: 'Others', code: 'others' }
])

const firstName = ref('')
const middleName = ref<string | undefined>(undefined)
const lastName = ref('')
const gender = ref<Gender | null>(null)
const dateOfBirth = ref<Date>()
const phoneNumber = ref('')
const email = ref<string | null>(null)
const aadharNumber = ref('')
const panNumber = ref('')
const drivingLicence = ref<string | undefined>(undefined)
const houseNumber = ref('')
const addressLine1 = ref('')
const addressLine2 = ref<string | undefined>(undefined)
const city = ref('')
const taluk = ref('')
const district = ref('')
const pincode = ref('')
const sbAccountVoucher = ref<number>()
const shareholderVoucher = ref<number>()
const initialAmount = ref<number>()
const shareAmount = ref<number>()
const isShareholder = ref<boolean>(true)
const createdCustomer = ref<AddCustomerResponseData>()
const maxDate = ref(new Date())

function handleAddCustomer() {
  if (!ValidationUtility.validateString(firstName.value)) {
    showToast('First name is invalid/empty', 'Validation failed!', 'error')
    return
  }

  if (middleName.value && !ValidationUtility.validateString(middleName.value)) {
    showToast('Middle name is invalid', 'Validation failed!', 'error')
    return
  }

  if (!ValidationUtility.validateString(lastName.value)) {
    showToast('Last name is invalid/empty', 'Validation failed!', 'error')
    return
  }

  if (!gender.value) {
    showToast('Gender not selected', 'Validation failed!', 'error')
    return
  }

  if (!phoneNumber.value || phoneNumber.value.startsWith('0')) {
    showToast('Phone number is invalid/empty', 'Validation failed!', 'error')
    return
  }

  if (Number(phoneNumber.value[0]) != 6 && Number(phoneNumber.value[0]) != 7 && Number(phoneNumber.value[0]) != 8 && Number(phoneNumber.value[0]) != 9) {
    showToast('Invalid Phone Number', 'Phone Number must start with (6, 7, 8, 9)', 'error');
    return
  }

  if (email.value && !ValidationUtility.checkEmail(email.value)) {
    showToast('Email is invalid', 'Validation failed!', 'error')
    return
  }

  if (!dateOfBirth.value) {
    showToast('Date of birth is invalid/empty', 'Validation failed!', 'error')
    return
  }

  if (!aadharNumber.value || aadharNumber.value.startsWith('0')) {
    showToast('Aadhar number is invalid/empty', 'Validation failed!', 'error')
    return
  }

  if (!panNumber.value) {
    showToast('PAN number is invalid/empty', 'Validation failed!', 'error')
    return
  }

  if (!houseNumber.value) {
    showToast('House no is invalid/empty', 'Validation failed!', 'error')
    return
  }

  if (!addressLine1.value) {
    showToast('Address Line 1 is invalid/empty', 'Validation failed!', 'error')
    return
  }

  if (!ValidationUtility.validateString(city.value)) {
    showToast('City is invalid/empty', 'Validation failed!', 'error')
    return
  }

  if (city.value.length < 3) {
    showToast('City should be greater than 3 Characters', 'Validation failed!', 'error')
    return
  }

  if (!ValidationUtility.validateString(taluk.value)) {
    showToast('Taulk is invalid/empty', 'Validation failed!', 'error')
    return
  }

  if (taluk.value.length < 3) {
    showToast('Taluk should be greater than 3 Characters', 'Validation failed!', 'error')
    return
  }

  if (!ValidationUtility.validateString(district.value)) {
    showToast('District is invalid/empty', 'Validation failed!', 'error')
    return
  }

  if (district.value.length < 3) {
    showToast('District should be greater than 3 Characters', 'Validation failed!', 'error')
    return
  }

  if (!pincode.value || pincode.value.startsWith('0')) {
    showToast('Pincode is invalid/empty', 'Validation failed!', 'error')
    return
  }

  if (initialAmount.value) {
    if(initialAmount.value<0){
      showToast('Initial amount cannot be negative', 'Validation failed!', 'error')
      return
    }
    else if (!sbAccountVoucher.value) {
      showToast('SB voucher is empty', 'Validation failed!', 'error')
      return
    } else if (sbAccountVoucher.value.toString().length > 8) {
      showToast(
        'SB voucher is not of valid type',
        'Voucher number should be less than 8 digits!',
        'error'
      )
      return
    }
  }

  if (isShareholder.value && !ValidationUtility.validateNumber(String(shareAmount.value))) {
    showToast('Share Amount is invalid/empty', 'Validation failed!', 'error')
    return
  }

  if (isShareholder.value && !shareholderVoucher.value) {
    showToast('Shareholder voucher is empty', 'Validation failed!', 'error')
    return
  }

  if (
    isShareholder.value && shareholderVoucher?.value
      ? shareholderVoucher?.value.toString().length > 8
      : false
  ) {
    showToast(
      'Shareholder voucher is not of valid type',
      'Voucher number should be less than 8 digits!',
      'error'
    )
    return
  }

  const updatedBy: UpdatedBy = {
    empID: Storage.getEmpId()!!,
    updatedAt: Date.now()
  }

  const createBy: CreatedBy = {
    empId: Storage.getEmpId()!!,
    createdAt: Date.now()
  }
  const customer: CustomerWithoutID = {
    firstName: firstName.value.trim(),
    middleName: middleName.value ? middleName.value.trim() : undefined,
    lastName: lastName.value.trim(),
    gender: gender.value?.name ?? 'Male',
    dateOfBirth: new Date(dateOfBirth.value).getTime(),
    phoneNumber: phoneNumber.value.replace('-', ''),
    email: email.value ? email.value.trim().toLowerCase() : undefined,
    aadharNumber: aadharNumber.value.replace(/-/g, ''),
    panNumber: panNumber.value.toUpperCase(),
    drivingLicence: drivingLicence.value?.replace('-', '').toUpperCase(),
    houseNumber: houseNumber.value.trim(),
    addressLine1: addressLine1.value.trim(),
    addressLine2: addressLine2.value ? addressLine2.value.trim() : undefined,
    city: city.value.trim(),
    taluk: taluk.value.trim(),
    district: district.value.trim(),
    pincode: Number(pincode.value),
    createdAt: Date.now(),
    updatedAt: Date.now(),
    updatedBy: [updatedBy]
  }

  const shareHolderTransactions: ShareholderTransactions = {
    openingBalance: 0,
    deposit: ConversionUtility.toPaiseConversion(shareAmount.value??0),
    withdrawal: 0,
    closingBalance: ConversionUtility.toPaiseConversion(shareAmount.value??0),
    createdAt: Date.now(),
    createdBy: createBy,
    voucherNo: shareholderVoucher.value ?? 0,
    desc: 'Opening balance for shareholder'
  }

  const shareholder: ShareHolder = {
    shareAmount: shareAmount.value
      ? ConversionUtility.toPaiseConversion(shareAmount.value!!)
      : undefined,
    createdAt: Date.now(),
    createdBy: createBy,
    transactions: [shareHolderTransactions]
  }
  const createAccountRequest: CreateAccountRequest = {
    initDeposit: ConversionUtility.toPaiseConversion(initialAmount.value ?? 0),
    createdAt: Date.now(),
    src: 'Initial Deposit while creating account',
    voucherNo: initialAmount.value ? sbAccountVoucher.value!! : null,
    createdBy: createBy,
  }
  const addCustomerRequest: AddCustomerRequest = {
    shareholder: isShareholder.value ? shareholder : null,
    customer: customer,
    accountCreateRequest: createAccountRequest
  }
  
  if (isShareholder.value) {
    confirmDialog.require({
      message: `Are you sure you want to create this Customer with share amount?
      ${ConversionUtility.toStringRupeesConversion(Number(addCustomerRequest.shareholder?.shareAmount))}
      `,
      header: 'Confirmation to create Customer, Shareholder and Savings Account',
      icon: 'fas fa-exclamation-triangle',
      rejectProps: {
        label: 'Cancel',
        severity: 'secondary',
        outlined: true
      },
      acceptProps: {
        label: 'Yes, Create'
      },
      accept() {
        isLoading.value = true
        ApiService.addCustomer(addCustomerRequest)
          .then((response) => {
            setTimeout(() => {
              if (response.statusCode == 401) {
                Storage.clearData()
                router.push({
                  name: 'login'
                })
              }
            }, 2000)
            if (response.statusCode == 200) {
              createdCustomer.value = response.data
              isCustomerCreated.value = true
              clearInputFields()
              showToast(
                'Account created successfully',
                response.data?.customer.customerId!!,
                'success'
              )
            } else {
              showToast(
                'Failed to create customer',
                response.error ?? 'Something went wrong',
                'error'
              )
            }
          })
          .catch((error) => {
            showToast(
              'Failed to create customer',
              JSON.parse(CryptoUtil.decrypt(error.response.data)).error,
              'error'
            )
          })
          .finally(() => {
            isLoading.value = false
          })
      },
      reject() {}
    })
  } else {
    isLoading.value = true
    ApiService.addCustomer(addCustomerRequest)
      .then((response) => {
        setTimeout(() => {
          if (response.statusCode == 401) {
            Storage.clearData()
            router.push({
              name: 'login'
            })
          }
        }, 2000)
        if (response.statusCode == 200) {
          createdCustomer.value = response.data
          isCustomerCreated.value = true
          clearInputFields()
          showToast('Account created succesfully', response.data?.customer.customerId!!, 'success')
        } else {
          showToast('Failed to create customer', response.error ?? 'Something went wrong', 'error')
        }
      })
      .catch((error) => {
        showToast(
          'Failed to create customer',
          JSON.parse(CryptoUtil.decrypt(error.response.data)).error,
          'error'
        )
      })
      .finally(() => {
        isLoading.value = false
      })
  }
}

function clearInputFields() {
  firstName.value = ''
  middleName.value = undefined
  lastName.value = ''
  gender.value = null
  dateOfBirth.value = undefined
  phoneNumber.value = ''
  email.value = null
  aadharNumber.value = ''
  panNumber.value = ''
  drivingLicence.value = undefined
  houseNumber.value = ''
  addressLine1.value = ''
  addressLine2.value = undefined
  city.value = ''
  taluk.value = ''
  district.value = ''
  pincode.value = ''
  initialAmount.value = 0
  shareAmount.value = 0
  isShareholder.value = true
  sbAccountVoucher.value = undefined
  shareholderVoucher.value = undefined
}

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}
</script>

<template>
  <main class="main">
    <Card class="search-card">
      <template #title>
        <div class="text-3xl font-semibold">Add Customer</div>
      </template>
      <template #content>
        <Divider />
        <div class="form">
          <FloatLabel>
            <InputText id="firstName" v-model="firstName" class="input-text" size="large" />
            <label for="firstName" class="mandatory">First Name</label>
          </FloatLabel>

          <FloatLabel>
            <InputText id="middleName" v-model="middleName" class="input-text" size="large" />
            <label for="middleName">Middle Name</label>
          </FloatLabel>

          <FloatLabel>
            <InputText id="lastName" v-model="lastName" class="input-text" size="large" />
            <label for="lastName" class="mandatory">Last Name</label>
          </FloatLabel>

          <FloatLabel>
            <Select
              v-model="gender"
              inputId="gender"
              :options="genderOptions"
              optionLabel="name"
              class="gender-select"
              overlayClass="gender-overlay"
            />
            <label for="gender" class="mandatory">Select gender</label>
          </FloatLabel>

          <FloatLabel>
            <InputMask
              id="phoneNumber"
              v-model="phoneNumber"
              class="input-text"
              size="large"
              mask="99999-99999?"
              placeholder="999-99-9999?"
            />
            <label for="phoneNumber" class="mandatory">Phone Number</label>
          </FloatLabel>

          <FloatLabel>
            <InputText id="email" v-model="email" type="email" class="input-text" size="large" />
            <label for="email">Email ID</label>
          </FloatLabel>

          <FloatLabel>
            <label for="dateOfBirth" class="mandatory">Date Of Birth</label>
            <DatePicker
              v-model="dateOfBirth"
              id="dateOfBirth"
              class="input-text"
              :maxDate="maxDate"
              dateFormat="dd/mm/yy"
            />
          </FloatLabel>

          <FloatLabel>
            <InputMask
              id="aadharNumber"
              v-model="aadharNumber"
              class="input-text"
              size="large"
              mask="9999-9999-9999?"
              placeholder="9999-9999-9999?"
            />
            <label for="aadharNumber" class="mandatory">Aadhar Number</label>
          </FloatLabel>

          <FloatLabel>
            <InputMask
              id="panNumber"
              v-model="panNumber"
              class="input-text upper"
              size="large"
              mask="aaaaa9999a?"
              placeholder="aaaaa9999a?"
            />
            <label for="panNumber" class="mandatory">Pan Number</label>
          </FloatLabel>

          <FloatLabel>
            <InputMask
              id="drivingLicence"
              v-model="drivingLicence"
              class="input-text upper"
              size="large"
              mask="aa99-99999999999?"
              placeholder="aa99-99999999999?"
            />
            <label for="drivingLicence">Driving Licence</label>
          </FloatLabel>

          <FloatLabel>
            <InputText id="houseNumber" v-model="houseNumber" class="input-text" size="large" />
            <label for="houseNumber" class="mandatory">House No</label>
          </FloatLabel>

          <FloatLabel>
            <InputText id="addressLine1" v-model="addressLine1" class="input-text" size="large" />
            <label for="addressLine1" class="mandatory">Address Line 1</label>
          </FloatLabel>

          <FloatLabel>
            <InputText id="addressLine2" v-model="addressLine2" class="input-text" size="large" />
            <label for="addressLine2">Address Line 2</label>
          </FloatLabel>

          <FloatLabel>
            <InputText id="city" v-model="city" class="input-text" size="large" />
            <label for="city" class="mandatory">City</label>
          </FloatLabel>

          <FloatLabel>
            <InputText id="taluk" v-model="taluk" class="input-text" size="large" />
            <label for="taluk" class="mandatory">Taluk</label>
          </FloatLabel>

          <FloatLabel>
            <InputText id="district" v-model="district" class="input-text" size="large" />
            <label for="district" class="mandatory">District</label>
          </FloatLabel>

          <FloatLabel>
            <InputMask
              id="pincode"
              v-model="pincode"
              class="input-text"
              size="large"
              mask="999999?"
              placeholder="999999?"
            />
            <label for="pincode" class="mandatory">Pin Code</label>
          </FloatLabel>
        </div>
        <Divider />
        <h2 class="text-3xl font-bold">SB Account Details</h2>
        <Divider />
        <div class="form">
          <FloatLabel>
            <InputNumber
              id="initialAmount"
              input-id="locale-indian"
              locale="en-IN"
              mode="currency"
              currency="INR"
              v-model="initialAmount"
              :maxFractionDigits="0"
              class="input-text"
              size="large"
              :min="0"
            />
            <label for="initialAmount" class="mandatory">Initial Amount</label>
          </FloatLabel>
          <FloatLabel>
            <InputNumber
              id="sbVoucher"
              v-model="sbAccountVoucher"
              class="input-text"
              :min="1"
              :useGrouping="false"
            />
            <label for="sbVoucher" class="mandatory">Voucher Number</label>
          </FloatLabel>
        </div>
        <Divider />
        <div class="shareholder-title">
          <h2 class="text-3xl font-bold">Shareholder Details</h2>
          <ToggleButton
            v-model="isShareholder"
            onIcon="fas fa-check"
            offIcon="pi pi-times"
            aria-label="Confirmation"
            class="shareholder-toggle"
          />
        </div>
        <Divider />
        <div class="form">
          <FloatLabel>
            <InputNumber
              :disabled="!isShareholder"
              id="shareAmount"
              input-id="locale-indian"
              locale="en-IN"
              mode="currency"
              currency="INR"
              v-model="shareAmount"
              :maxFractionDigits="0"
              class="input-text"
              size="large"
              :min="0"
            />
            <label for="initialAmount" class="mandatory">Amount</label>
          </FloatLabel>
          <FloatLabel>
            <InputNumber
              :disabled="!isShareholder"
              :useGrouping="false"
              id="shareVoucher"
              v-model="shareholderVoucher"
              class="input-text"
              :min="1"
            />
            <label for="shareVoucher" class="mandatory">Voucher Number</label>
          </FloatLabel>
        </div>
        <div>
          <LoadingComponent
            text="Please wait while we process the request!"
            :dialogVisiblity="isLoading"
          />
        </div>
        <div class="button-group">
          <Toast class="error-p-toast" />
          <CustomButton title="Clear" icon="times" :outlined="true" @click="clearInputFields" />
          <CustomButton @click="handleAddCustomer" icon="add" title="Add Customer" />
        </div>
        <Dialog
          :visible="isCustomerCreated"
          modal
          :draggable="false"
          :closable="false"
          header="Customer created successfully"
        >
          <div>
            <p>Customer ID: {{ createdCustomer?.customer.customerId }}</p>
            <p>Sb Account ID: {{ createdCustomer?.accountCreateResponse?.account?.accountId }}</p>
            <p v-if="createdCustomer?.shareholder">
              ShareHolder ID: {{ createdCustomer?.shareholder?.id }}
            </p>
            <p v-if="createdCustomer?.shareholder">
              Share Amount:
              {{
                ConversionUtility.toStringRupeesConversion(
                  createdCustomer?.shareholder?.shareAmount
                )
              }}
            </p>
          </div>
          <CustomButton
            title="Close"
            icon="times"
            @click="
              () => {
                createdCustomer = undefined
                isCustomerCreated = false
              }
            "
          ></CustomButton>
        </Dialog>
        <ConfirmDialog :draggable="false" :closable="false" />
      </template>
    </Card>
  </main>
</template>

<style scoped>
html,
body,
.main {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  margin: 20px;
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: rgb(104, 103, 103);
  border-radius: 4px;
}

h2 {
  text-align: start;
  padding-left: 0.5rem;
  font-weight: 550;
}

.upper {
  text-transform: uppercase;
}

.form {
  margin: 2rem 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.shareholder-title {
  display: flex;
  align-items: center;

  .shareholder-toggle {
    margin-left: 2rem;
  }
}

.mandatory::after {
  content: ' *';
  color: red;
}

.input-text {
  height: 45px;
}

.gender-select {
  width: 100%;
  height: 45px;
  font-family: Epilogue, serif;
}

.gender-select {
  color: rgb(50, 95, 186);
}

.button-group {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: 10px;
}
</style>
