<script setup lang="ts">
import SearchComponent from '@/components/SearchComponent.vue'
import NotFound from '@/assets/not_found.svg'
import { ref } from 'vue'
import CustomButton from '@/components/CustomComponent/CustomButton.vue'
import type { SelectModel } from '@/models/SelectModel'
import type { SearchedLoan } from '@/api/models/loan/SearchedLoan'
import { useToast } from 'primevue/usetoast'
import type { ToastMessageOptions } from 'primevue/toast'
import type { SearchLoanRequest } from '@/api/models/loan/SearchLoanRequest'
import ApiService from '@/api/ApiService'
import router from '@/router'
import Storage from '@/utils/Storage'
import LoadingComponent from '@/components/LoadingComponent.vue'
import ConversionUtility from '@/utils/ConversionUtility'
import type { EMIPayment } from '@/api/models/loan/EMIPayment'
import type { AmortizationEMISchedule } from '@/models/AmortizationEMISchedule'
import { InterestAndTotalCalculator } from '@/utils/InterestAndTotalCalculator'
import DateUtils from '@/utils/DateUtils'
import type { PayEMIRequest } from '@/api/models/loan/PayEMIRequest'
import type { EMITransactionsRequest } from '@/api/models/loan/EMITransactionsRequest'
import type { CreatedBy } from '@/api/models/CreatedBy'

const loan = ref<SearchedLoan>()
const toast = useToast()
const isLoanDetailsVisible = ref<boolean>(false)
const isLoanTransactionsVisible = ref<boolean>(false)
const isPayEMIVisible = ref<boolean>(false)
const isLoading = ref<boolean>(false)
const isEMIScheduleVisible = ref<boolean>(false)
const emiTransactions = ref<EMIPayment[]>([])

//EMI variables
const emiAmount = ref<number>()
const voucherNo = ref<number>()
const penaltyAmount = ref<number>()
const lumpSumAmount = ref<number>()
const paymentOptions = ref<SelectModel[]>([
  { value: 'EMI', label: 'EMI' },
  { value: 'Lump Sum', label: 'Lump Sum' }
])
const selectedPaymentOption = ref<SelectModel>(paymentOptions.value[0])
const emiSchedule = ref<AmortizationEMISchedule[]>([])
const emiNo = ref<number>()

function handelLoanSearch(query: string) {
  loan.value = undefined
  isLoading.value = false
  isLoanDetailsVisible.value = false
  isLoanTransactionsVisible.value = false
  isPayEMIVisible.value = false
  emiSchedule.value = []
  isEMIScheduleVisible.value=false
  emiNo.value = undefined
  emiTransactions.value = []
  isLoanTransactionsVisible.value = false
  if (!query) {
    showToast('Invalid search query', 'Please provide search details of the loan', 'error')
    return
  }
  if (!(query.length == 6)) {
    showToast('Invalid search query', 'Loan ID is at least 6 characters long', 'error')
    return
  }
  isLoading.value = true
  const searchLoan: SearchLoanRequest = { loanId: query }
  ApiService.searchLoan(searchLoan)
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        loan.value = response.data
        emiSchedule.value = InterestAndTotalCalculator.generateAmortizationSchedule(
          loan.value.loanAmount!!,
          loan.value.interestRate!!,
          loan.value.tenureInMonths   
        )
        emiNo.value = DateUtils.monthDifference(new Date(loan.value.loanStartDate),new Date(loan.value.nextDueDate)) - (new Date(loan.value.loanStartDate).getDate()<=loan.value.emiDate?1:0)
        emiAmount.value = ConversionUtility.toRuppesCoversion(loan.value.emiAmount!!)
      } else {
        showToast('Failed to fetch customers', response.error ?? 'No error response!', 'error')
      }
    })
    .catch((error) => {
      showToast('Failed to fetch customers', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function payEMI() {
  if (!loan.value) {
    showToast('Invalid loan', 'Please select a valid loan', 'error')
    return
  }
  if (!emiAmount.value && selectedPaymentOption.value.value == 'EMI') {
    showToast('EMI Amount is empty/blank', 'Enter a valid EMI amount', 'error')
    return
  }
  if (!voucherNo.value) {
    showToast('Voucher Number is empty/blank', 'Enter a valid voucher number', 'error')
    return
  }
  if (!lumpSumAmount.value && selectedPaymentOption.value.value == 'Lump Sum') {
    showToast('Lump Sum Amount is empty/blank', 'Enter a valid lump sum amount', 'error')
    return
  }
  if (penaltyAmount.value){
    if (Number(penaltyAmount.value) > Number(loan.value.emiAmount!!)) {
      showToast(
        'Penalty Amount should be less than EMI Amount',
        'Enter a valid penalty amount',
        'error'
      )
      return
    }
  }
  
  const createdBy: CreatedBy = {
    empId:Storage.getEmpId()!!,
    createdAt: new Date().getTime()
  }

  const EMIPayment: EMIPayment = {
    createdAt: new Date().getTime(),
    principalPaid: Number(emiSchedule.value[emiNo.value!!].principal.toFixed(0)),
    interestPaid: Number(emiSchedule.value[emiNo.value!!].interest.toFixed(0)),
    penaltyCharges: ConversionUtility.toPaiseConversion(penaltyAmount.value ?? 0),
    voucherNumber: voucherNo.value,
    dueDate: DateUtils.emiDate(loan.value.emiDate, emiNo.value!!+2,loan.value.loanStartDate),
    paymentStatus: 'PAID',
    createdBy:createdBy
  }
  
  const EMI: PayEMIRequest = {
    loanId: loan.value.loanId,
    nextEmiDate: DateUtils.emiDate(loan.value.emiDate, emiNo.value!!+3,loan.value.loanStartDate),
    emiPayment: EMIPayment
  }
  console.log(new Date(EMI.nextEmiDate).toLocaleDateString());
  
  isLoading.value = true
  ApiService.payEMI(EMI)
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        showToast('EMI paid successfully', 'EMI paid successfully', 'success')
        handelLoanSearch((loan.value?.loanId!!).toString())
      } else {
        showToast('Failed to pay EMI', response.data ?? 'No error response!', 'error')
      }
    })
    .catch((error) => {
      showToast('Failed to pay EMI', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function getEmiTransactions() {
  if (!loan.value) {
    showToast('Invalid loan', 'Please select a valid loan', 'error')
    return
  }
  isLoading.value = true
  const getEmiTransactions: EMITransactionsRequest = { loanId: loan.value.loanId.toString() }
  ApiService.getEMITransactions(getEmiTransactions)
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        emiTransactions.value = response.data
      } else {
        isLoanTransactionsVisible.value = false
        showToast('Failed to fetch EMI transactions', response.error ?? 'No error response!', 'error')
      }
    })
    .catch((error) => {
      showToast('Failed to fetch EMI transactions', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}
</script>
<template>
  <main class="main">
    <Card class="search-card">
      <template #content>
        <Toast class="error-p-toast" />
        <SearchComponent
          title="Search Loans"
          subtitle="Search for Loans using Loan ID"
          placeholder="Search using Loan ID"
          @search-click="handelLoanSearch"
        />
        <div v-if="!loan && !isLoading" class="notFound">
          <Image :src="NotFound" width="800" />
          <Chip label="No accounts found for the provided search query!" style="font-weight: 600" />
        </div>
        <div v-if="isLoading">
          <LoadingComponent
            text="Please wait while we process the request!"
            :dialogVisiblity="isLoading"
          />
        </div>
        <div v-if="loan && !isLoading" class="cont">
          <div class="holder-details">
            <div>
              <span class="text-heading">Customer ID: </span>
              <span class="text">{{ loan.customerId }}<br /></span>
            </div>
            <!-- loan end date is not correct from backend so we hid end date and tenures. -->
            <!-- <div>
              <span class="text-heading">Tenure: </span>
              <span class="text">{{ ConversionUtility.toDateFormat(loan.loanEndDate) }}</span>
            </div> -->
            <div>
              <span class="text-heading">Next EMI Date: </span>
              <span class="text">{{ ConversionUtility.toDateFormat(loan.nextDueDate) }}</span>
            </div>
            <div>
              <span class="text-heading">Loan Amount: </span>
              <span class="text"
                >{{ ConversionUtility.toStringRupeesConversion(loan.loanAmount) }}<br
              /></span>
            </div>
            <div>
              <span class="text-heading">Started On: </span>
              <span class="text">{{ ConversionUtility.toDateFormat(loan.loanStartDate) }}</span>
            </div>
            <div>
              <span class="text-heading">Status: </span>
              <Tag :severity="loan.loanStatus == 'ACTIVE' ? 'success' : 'warn'" class="text"
                >{{ loan.loanStatus }}<br
              /></Tag>
            </div>
          </div>
          <div class="operations">
            <div class="button-transactions">
              <CustomButton
                title="View Loan Transcation"
                icon="book"
                @click="
                  () => {
                    isLoanTransactionsVisible = !isLoanTransactionsVisible
                    isLoanDetailsVisible = false
                    isPayEMIVisible = false
                    isEMIScheduleVisible = false
                    getEmiTransactions()
                  }
                "
              />
              <CustomButton
                title="View Loan Details"
                icon="angle-double-down"
                @click="
                  () => {
                    isLoanDetailsVisible = !isLoanDetailsVisible
                    isLoanTransactionsVisible = false
                    isPayEMIVisible = false
                    isEMIScheduleVisible = false
                  }
                "
              />
              <CustomButton
                title="EMI Schedule"
                icon="calendar"
                @click="
                  () => {
                  isEMIScheduleVisible = !isEMIScheduleVisible
                  isLoanDetailsVisible = false
                  isLoanTransactionsVisible = false
                  isPayEMIVisible = false
                }
                "
              />
            </div>
            <div>
              <CustomButton
                title="Pay EMI"
                icon="indian-rupee"
                :outlined="true"
                @click="
                  () => {
                    voucherNo = undefined
                    penaltyAmount = undefined
                    isPayEMIVisible = !isPayEMIVisible
                    isLoanDetailsVisible = false
                    isLoanTransactionsVisible = false
                    isEMIScheduleVisible = false
                  }
                "
              />
            </div>
          </div>
        </div>
        <Transition>
          <div v-if="isLoanTransactionsVisible">
            <div class="cont">
              <DataTable :value="emiTransactions" :paginator="true" :rows="10" :rowsPerPageOptions="[10, 25, 50]">
                <Column header="Paid On">
                <template #body="slotProps">
                  {{ ConversionUtility.toDateFormat(slotProps.data.createdAt) }}
                </template>
                </Column>
                <Column header="Principal Paid">
                <template #body="slotProps">
                  {{ ConversionUtility.toStringRupeesConversion(slotProps.data.principalPaid) }}
                </template>
                </Column>
                <Column header="Interest Paid">
                <template #body="slotProps">
                  {{ ConversionUtility.toStringRupeesConversion(slotProps.data.interestPaid) }}
                </template>
                </Column>
                <Column header="Penalty">
                <template #body="slotProps">
                  {{ ConversionUtility.toStringRupeesConversion(slotProps.data.penaltyCharges) }}
                </template>
                </Column>
                <Column header="Total">
                <template #body="slotProps">
                  {{ ConversionUtility.toStringRupeesConversion(slotProps.data.interestPaid+slotProps.data.principalPaid+slotProps.data.penaltyCharges) }}
                </template>
                </Column>
                <Column header="Voucher No">
                <template #body="slotProps">
                  {{ slotProps.data.voucherNumber }}
                </template>
                </Column>
                <Column header="Due On">
                <template #body="slotProps">
                  {{ ConversionUtility.toDateFormat(slotProps.data.dueDate) }}
                </template>
                </Column>
              </DataTable>
            </div>
          </div>
        </Transition>
        <Transition>
          <div v-if="isLoanDetailsVisible" class="cont">
            <div class="details-container">
              <Panel header="Loan Details">
                <Divider style="margin: 0" />
                <div class="details pt-5">
                  <div>
                    <span class="text-heading">Customer ID: </span>
                    <span class="text">{{ loan?.customerId }}<br /></span>
                  </div>
                  <div>
                    <span class="text-heading">Application ID: </span>
                    <span class="text">{{ loan?.applicationId }}<br /></span>
                  </div>
                  <div>
                    <span class="text-heading">ID: </span>
                    <span class="text">{{ loan?.loanId }}<br /></span>
                  </div>
                  <div>
                    <span class="text-heading">Type: </span>
                    <span class="text">{{ loan?.loanType }}<br /></span>
                  </div>
                  <div>
                    <span class="text-heading">Start Date: </span>
                    <span class="text"
                      >{{ ConversionUtility.toDateFormat(loan?.loanStartDate ?? 0) }}<br
                    /></span>
                  </div>
                  <!-- <div>
                    <span class="text-heading">End Date: </span>
                    <span class="text"
                      >{{ ConversionUtility.toDateFormat(loan?.loanEndDate ?? 0) }}<br
                    /></span>
                  </div> -->
                  <div>
                    <span class="text-heading">Tenure: </span>
                    <span class="text"
                      >{{
                        (loan?.tenureInMonths ?? 0) > 12
                          ? Math.floor(loan?.tenureInMonths!! / 12) +
                            ' Years ' +
                            (loan?.tenureInMonths!! % 12 > 0
                              ? (loan?.tenureInMonths!! % 12) + ' Months'
                              : '')
                          : loan?.tenureInMonths + ' Months'
                      }}<br
                    /></span>
                  </div>
                  <div>
                    <span class="text-heading">Interest Rate: </span>
                    <span class="text">{{ loan?.interestRate }} %<br /></span>
                  </div>
                  <div>
                    <span class="text-heading">Collateral Details: </span>
                    <span class="text">{{ loan?.collateralDetails }}<br /></span>
                  </div>
                </div>
              </Panel>
              <Panel header="Amount Details">
                <Divider style="margin: 0" />
                <div class="details pt-5">
                  <div>
                    <span class="text-heading">Principal Amount: </span>
                    <span class="text"
                      >{{ ConversionUtility.toStringRupeesConversion(loan?.loanAmount ?? 0) }}<br
                    /></span>
                  </div>
                  <div>
                    <span class="text-heading">Interest Amount: </span>
                    <span class="text"
                      >{{ ConversionUtility.toStringRupeesConversion(loan?.interestAmount ?? 0)
                      }}<br
                    /></span>
                  </div>
                  <div>
                    <span class="text-heading">Outstanding Amount: </span>
                    <span class="text"
                      >{{ ConversionUtility.toStringRupeesConversion(loan?.outstandingAmount ?? 0)
                      }}<br
                    /></span>
                  </div>
                  <div>
                    <span class="text-heading">Remaining Principal Amount: </span>
                    <span class="text"
                      >{{
                        ConversionUtility.toStringRupeesConversion(
                          loan?.remainingPrincipalAmount ?? 0
                        )
                      }}<br
                    /></span>
                  </div>
                  <div>
                    <span class="text-heading">Remaining Interest Amount: </span>
                    <span class="text"
                      >{{
                        ConversionUtility.toStringRupeesConversion(
                          loan?.remainingInterestAmount ?? 0
                        )
                      }}<br
                    /></span>
                  </div>
                  <div>
                    <span class="text-heading">Total Paid: </span>
                    <span class="text"
                      >{{ ConversionUtility.toStringRupeesConversion(loan?.totalPaid ?? 0) }}<br
                    /></span>
                  </div>
                </div>
              </Panel>
              <Panel header="Bank Details" class="my-5">
                <Divider style="margin: 0" />
                <div class="flex flex-col gap-5 p-5">
                  <div>
                    <span class="text-heading">Branch Code: </span>
                    <span class="text">{{ loan?.branchCode }}<br /></span>
                  </div>
                  <div>
                    <span class="text-heading">Created On: </span>
                    <span class="text"
                      >{{ ConversionUtility.toDateAndTimeFormat(loan?.createdAt ?? 0) }}<br
                    /></span>
                  </div>
                  <div>
                    <span class="text-heading">Updated On: </span>
                    <span class="text"
                      >{{ ConversionUtility.toDateAndTimeFormat(loan?.updateAt ?? 0) }}<br
                    /></span>
                  </div>
                </div>
              </Panel>
              <Panel header="EMI Details" class="my-5">
                <Divider style="margin: 0" />
                <div class="flex flex-col gap-5 p-5">
                  <div>
                    <span class="text-heading">EMI Date: </span>
                    <span class="text"
                      >{{
                        loan?.emiDate == 1
                          ? loan.emiDate + ' st'
                          : loan?.emiDate == 2
                            ? loan.emiDate + ' nd'
                            : loan?.emiDate == 3
                              ? loan.emiDate + ' rd'
                              : loan?.emiDate + ' th  '
                      }}<br
                    /></span>
                  </div>
                  <div>
                    <span class="text-heading">Next EMI On: </span>
                    <span class="text"
                      >{{ ConversionUtility.toDateFormat(loan?.nextDueDate ?? 0) }}<br
                    /></span>
                  </div>
                  <div>
                    <span class="text-heading">EMI: </span>
                    <span class="text"
                      >{{ ConversionUtility.toStringRupeesConversion(loan?.emiAmount ?? 0) }}<br
                    /></span>
                  </div>
                </div>
              </Panel>
            </div>
            <div class="operations">
              <CustomButton
                title="Close Loan"
                icon="times"
                severity="danger"
                :disabled="true"
              ></CustomButton>
            </div>
          </div>
        </Transition>
        <Transition>
          <div v-if="isPayEMIVisible" class="cont">
            <h1 class="text-2xl font-bold flex justify-start pl-5 pb-2">Pay EMI</h1>
            <Divider style="margin: 0" />
            <!-- <SelectButton
              class="m-5"
              :options="paymentOptions"
              optionLabel="label"
              v-model="selectedPaymentOption"
              :allowEmpty="false"
            ></SelectButton> -->
            <div class="operations flex gap-5  mt-5">
              <div class="w-[90%] flex flex-row gap-5">
                <InputNumber
                  v-if="selectedPaymentOption.value == 'EMI'"
                  v-model="emiAmount"
                  :min="0"
                  placeholder="Enter Amount"
                  locale="en-IN"
                  class="input-text"
                  prefix="₹ "
                  :disabled="true"
                ></InputNumber>
                <InputNumber
                  v-else
                  v-model="lumpSumAmount"
                  :min="0"
                  placeholder="Enter Lump Sum Amount"
                  locale="en-IN"
                  class="input-text"
                  prefix="₹ "
                ></InputNumber>
                <InputNumber
                  v-model="penaltyAmount"
                  :min="0"
                  placeholder="Enter Penalty Amount"
                  locale="en-IN"
                  class="input-text"
                  prefix="₹ "
                ></InputNumber>
                <InputNumber
                  v-model="voucherNo"
                  :min="1"
                  placeholder="Enter voucher number"
                  class="input-text"
                  :useGrouping="false"
                ></InputNumber>
              </div>
              <CustomButton title="Pay EMI" icon="indian-rupee" @click="payEMI" />
            </div>
          </div>
        </Transition>
        <Transition>
          <div v-if="isEMIScheduleVisible" class="cont">
            <DataTable :value="emiSchedule" responsiveLayout="scroll">
              <Column field="date" header="Date">
              <template #body="slotProps">
                {{ ConversionUtility.toDateFormat(DateUtils.emiDate(loan?.emiDate!!,slotProps.data.month+1,loan?.loanStartDate!!)) }}
              </template>
              </Column>
              <Column field="emi" header="EMI" class="w-1/6">
              <template #body="slotProps">
                {{ ConversionUtility.toStringRupeesConversion(slotProps.data.emi) }}
              </template>
              </Column>
              <Column field="principal" header="Principal" class="w-1/6">
                <template #body="slotProps">
                {{ ConversionUtility.toStringRupeesConversion(slotProps.data.principal) }}
              </template></Column>
              <Column field="interest" header="Interest" class="w-1/6">
                <template #body="slotProps">
                {{ ConversionUtility.toStringRupeesConversion(slotProps.data.interest) }}
              </template></Column>
              <Column field="outstandingPrincipal" header="Outstanding Principal">
                <template #body="slotProps">
                {{ ConversionUtility.toStringRupeesConversion(slotProps.data.outstandingPrincipal) }}
              </template></Column>
              <Column header="Status">
              <template #body="slotProps">
                <Tag :severity="slotProps.data.month==emiNo!!+1 ? 'warn' : slotProps.data.month < emiNo!!+1 ? 'success' : 'secondary'">{{ slotProps.data.month==emiNo!!+1 ? 'Upcoming' : slotProps.data.month < emiNo!!+1 ? 'Paid' : 'Pending' }}</Tag>
              </template></Column>
            </DataTable>
          </div>
        </Transition>
      </template>
    </Card>
  </main>
</template>
<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.25s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

h1 {
  margin: 0.5rem;
}

h2 {
  margin: 0;
}

.details-container {
  display: grid;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  text-align: start;

  .details {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
  }
}
</style>
