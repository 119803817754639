<script setup lang="ts">
import ApiService from '@/api/ApiService'
import type { SearchCustomerRequest } from '@/api/models/customer/SearchCustomerRequest'
import type { ToastMessageOptions } from 'primevue/toast'
import type { SelectCustomer } from '@/api/models/customer/SelectCustomer'
import type { CustomerDetailsRequest } from '@/api/models/customer/CustomerDetailsRequest'
import type { CustomerDetailsData } from '@/api/models/customer/CustomerDetailsData'
import type { CustomerDataTable } from '@/models/CustomerDataTable'
import { useToast } from 'primevue/usetoast'
import { ref } from 'vue'
import { CustomerMapper } from '@/utils/CustomerDataTableMapper'
import { useRouter } from 'vue-router'
import ConversionUtility from '@/utils/ConversionUtility'
import SearchComponent from '@/components/SearchComponent.vue'
import Storage from '@/utils/Storage'
import LoadingComponent from '@/components/LoadingComponent.vue'
import NotFound from '@/assets/not_found.svg'
import CustomButton from '@/components/CustomComponent/CustomButton.vue'
import type { Pigmy } from '@/api/models/pigmy/Pigmy'

const toast = useToast()
const router = useRouter()
const customer = ref<CustomerDetailsData>()
const selectCustomer = ref<SelectCustomer>()
const mappedCustomers = ref<CustomerDataTable[]>([])
const searchQuery = ref('')
const isLoading = ref(false)
const visibleBottom = ref(false)

const columns = [
  { field: 'customerId', header: 'Cust ID' },
  { field: 'customerName', header: 'Name' },
  { field: 'phoneNumber', header: 'Phone Number' },
  { field: 'aadharNumber', header: 'Aadhar Number' },
  { field: 'panNumber', header: 'PAN Number' },
  { field: 'address', header: 'Address' },
  { field: 'pincode', header: 'Pincode' }
]

const CustomerAccountDetails = [
  { field: 'accountId', header: 'ID / Number' },
  { field: 'accountType', header: 'Type' }
]

const CustomerDetails = [
  { field: 'customerId', header: 'Cust ID' },
  { field: 'name', header: 'Name' },
  { field: 'gender', header: 'Gender' },
  { field: 'dateOfBirth', header: 'DOB' },
  { field: 'phoneNumber', header: 'Phone Number' },
  { field: 'email', header: 'Email' },
  { field: 'aadharNumber', header: 'Aadhar Number' },
  { field: 'panNumber', header: 'PAN Number' },
  { field: 'drivingLicence', header: 'Driving Licence' },
  { field: 'address', header: 'Address' },
  { field: 'city', header: 'City' },
  { field: 'taluk', header: 'Taluk' },
  { field: 'district', header: 'District' },
  { field: 'pincode', header: 'Pincode' },
  { field: 'updatedAt', header: 'Created on' }
]

const pigmyAccountDetails = [
  { field: 'id', header: 'ID' }
]

const shareholderDetails = [
  { field: 'id', header: 'ID' },
  { field: 'customerId', header: 'Customer ID' }
]

const loanDetails = [
  { field: 'id', header: 'ID' },
  {field:'loanType',header:'Loan Type'},
  {field: 'status',header:'Status'},
]

function handleCustomerSearch(query: string) {
  isLoading.value = true
  searchQuery.value = query
  if (!searchQuery.value) {
    showToast('Invalid search query', 'Please provide search details of the customer', 'error')
    isLoading.value = false
    mappedCustomers.value = []
    return
  }
  const searchCustomerRequest: SearchCustomerRequest = {
    query: searchQuery.value
  }
  ApiService.searchCustomer(searchCustomerRequest)
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        mappedCustomers.value = CustomerMapper.mapToTableData(response.data ?? [])
      } else {
        mappedCustomers.value = []
        showToast('Failed to search customer', response.error ?? 'No error response!', 'error')
      }
    })
    .catch((error) => {
      mappedCustomers.value = []
      showToast('Failed to fetch customers', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

const onRowClick = (onClickResponseData: any) => {
  isLoading.value = true
  const getCustomerRequest: CustomerDetailsRequest = {
    cusId: String(onClickResponseData.data.customerId)
  }
  ApiService.getCustomerDetails(getCustomerRequest)
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        customer.value = response.data
        selectCustomer.value = CustomerMapper.mapToSelectCustomer(customer.value.customer)
        visibleBottom.value = true
      } else {
        showToast('Failed to fetch customers', response.error ?? 'No error response!', 'error')
      }
    })
    .catch((error) => {
      showToast('Failed to fetch customers', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}
</script>

<template>
  <main class="main">
    <Toast class="error-p-toast" />
    <card class="hereCard">
      <template #content>
        <SearchComponent
          title="Search Customer"
          subtitle="Search customers using Customer ID, Phone Number or First Name."
          placeholder="Search Customer"
          @search-click="handleCustomerSearch"
        />
        <div v-if="mappedCustomers.length <= 0 && !isLoading" class="notFound">
          <Image :src="NotFound" width="800" />
          <Chip
            label="No customers found for the provided search query!"
            style="font-weight: 600"
          />
        </div>
        <div v-if="mappedCustomers.length > 0 && !isLoading">
          <DataTable
            :value="mappedCustomers"
            paginator
            :rows="10"
            :rows-per-page-options="[5, 10, 20]"
            selection-mode="single"
            @row-click="onRowClick"
          >
            <Column
              v-for="col of columns"
              :key="col.field"
              :field="col.field"
              :header="col.header"
            />
            <template #paginatorend>
              <CustomButton
                title="clear"
                icon="times"
                @click="mappedCustomers = []"
                :outlined="true"
              />
            </template>
          </DataTable>
        </div>
        <div v-if="isLoading">
          <LoadingComponent
            text="Please wait while we process the request!"
            :dialogVisiblity="isLoading"
          />
        </div>
      </template>
    </card>
    <Drawer
      v-model:visible="visibleBottom"
      header="Customer Details"
      position="bottom"
      style="height: 90vh"
    >
      <section style="overflow-y: scroll">
        <Divider style="margin-top: 0" />
        <section class="selectCustomerSection">
          <div v-for="column in CustomerDetails" :key="column.field" class="selectCustomerDiv">
            <span class="header">{{ column.header }}</span>
            <span class="field">{{
                (selectCustomer as any)[`${column.field}`]
                  ? (selectCustomer as any)[`${column.field}`]
                  : ' ---'
              }}</span>
          </div>
        </section>
        <Divider />
        <h2 class="text-2xl font-bold" style="font-weight: 550">Account Details</h2>
        <Divider />
        <section class="selectCustomerSection" v-if="customer?.sbAccount">
          <div v-for="det in CustomerAccountDetails" :key="det.field"  class="selectCustomerDiv">
            <span class="header">{{ det.header }}</span>
            <span class="field">{{
                (customer.sbAccount as any)[`${det.field}`]
                  ? (customer.sbAccount as any)[`${det.field}`]
                  : ' ---'
              }}</span>
          </div>
          <div class="selectCustomerDiv">
            <span class="header">Balance</span>
            <span class="field">{{
                customer.sbAccount?.balance
                  ? ConversionUtility.toStringRupeesConversion(customer.sbAccount?.balance)
                  : '--'
              }}</span>
          </div>
          <div class="selectCustomerDiv">
            <span class="header">Status</span>
            <span class="field">{{ customer.sbAccount?.isClosed ? 'Closed' : 'Active' }}</span>
          </div>
          <div class="selectCustomerDiv">
            <span class="header">Created on</span>
            <span class="field">{{
                customer.sbAccount?.createdAt
                  ? ConversionUtility.toDateFormat(customer.sbAccount?.createdAt)
                  : '--'
              }}</span>
          </div>
        </section>
        <section v-else class="no-details-section">No SB Account Attached</section>
        <Divider />
        <h2 class="text-2xl font-bold" style="font-weight: 550">Pigmy Details</h2>
        <Divider />
        <section class="selectCustomerSection cont" v-if="(customer?.pigmyAccount as any)[0]" >
          <DataTable :value="(customer!!.pigmyAccount as any)" paginator :rows="5">
            <Column v-for="col in pigmyAccountDetails" :key="col.field" :field="col.field" :header="col.header" />
            <Column header="Pigmy Balance" field="balance" >
              <template #body="slotProps">
                {{ ConversionUtility.toStringRupeesConversion(slotProps.data.balance) }}
              </template>
            </Column>
            <Column header="Pigmy Amount" field="pigmyAmount" >
              <template #body="slotProps">
                {{ ConversionUtility.toStringRupeesConversion(slotProps.data.pigmyAmount) }}
              </template>
            </Column><Column header="Created On" field="createdAt" >
            <template #body="slotProps">
              {{ ConversionUtility.toDateFormat(slotProps.data.createdAt) }}
            </template>
          </Column>
          </DataTable>
        </section>
        <section v-else class="no-details-section">No Pigmy Account Attached</section>
        <Divider />
        <h2 class="text-2xl font-bold" style="font-weight: 550">FD Details</h2>
        <Divider />
        <section class="selectCustomerSection" v-if="(customer?.fdAccount as any)[0]">
          <DataTable :value="(customer!!.fdAccount as any)">
            <Column header="Id" field="fdId" />
            <Column header="Principal Amount" field="principleAmount" >
              <template #body="slotProps">
                {{ ConversionUtility.toStringRupeesConversion(slotProps.data.principleAmount) }}
              </template>
            </Column>
            <Column header="Rate of Interest" field="rateOfInterest" >
              <template #body="slotProps">
                {{ slotProps.data.rateOfInterest }} %
              </template>
            </Column>
            <Column header="Matures On" field="maturityAt" >
            <template #body="slotProps">
              {{ ConversionUtility.toDateFormat(slotProps.data.maturityAt) }}
            </template>
          </Column>
            <Column header="Created On" field="createdAt" >
            <template #body="slotProps">
              {{ ConversionUtility.toDateFormat(slotProps.data.createdAt) }}
            </template>
          </Column>
          </DataTable>
        </section>
        <section v-else class="no-details-section">The Customer does not have any FD's.</section>
        <Divider />
        <h2 class="text-2xl font-bold" style="font-weight: 550">Loan Details</h2>
        <Divider />
        <section class="selectCustomerSection" v-if="(customer?.loanAccount as any)[0]">
          <DataTable :value="(customer!!.loanAccount as any)">
            <Column header="Id" field="loanId" />
            <Column header="Loan Type" field="loanType" />
            <Column header="Application Id" field="applicationId" />
            <Column header="Principal Amount" field="loanAmount" >
              <template #body="slotProps">
                {{ ConversionUtility.toStringRupeesConversion(slotProps.data.loanAmount) }}
              </template>
            </Column>
            <Column header="Rate of Interest" field="interestRate" >
              <template #body="slotProps">
                {{ slotProps.data.interestRate }} %
              </template>
            </Column>
            <Column header="Created On" field="createdAt" >
            <template #body="slotProps">
              {{ ConversionUtility.toDateFormat(slotProps.data.createdAt) }}
            </template>
          </Column>
          <Column header="Status">
          <template #body="slotProps">
            <Tag :severity="slotProps.data.loanStatus == 'ACTIVE' ? 'success' : 'warn'" class="text"
                >{{ slotProps.data.loanStatus }}<br
              /></Tag>
          </template>
          </Column>
          </DataTable>
        </section>
        <section v-else class="no-details-section">The Customer does not have any Loan accounts open.</section>
        <Divider />
        <h2 class="text-2xl font-bold" style="font-weight: 550">Shareholder Details</h2>
        <Divider />
        <section class="selectCustomerSection" v-if="customer?.shareholder">
          <div v-for="details in shareholderDetails" :key="details.field" class="selectCustomerDiv">
            <span class="header">{{ details.header }}</span>
            <span class="field">{{(customer.shareholder as any)[`${details.field}`]}}</span>
          </div>
          <div class="selectCustomerDiv">
            <span class="header">Share Amount</span>
            <span class="field">{{
                  ConversionUtility.toStringRupeesConversion(customer.shareholder?.shareAmount)
              }}</span>
          </div>
          <div class="selectCustomerDiv">
            <span class="header">Created on</span>
            <span class="field">{{
                ConversionUtility.toDateFormat(customer.shareholder?.createdAt)
              }}</span>
          </div>
        </section>
        <section v-else class="no-details-section">The Customer is not a Shareholder.</section>
      </section>
    </Drawer>
  </main>
</template>

<style scoped>
.main {
  overflow-y: scroll;
}
.hereCard {
  width: 100%;
  margin-bottom: 4rem;
}
.selectCustomerSection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  row-gap: 16px;
}
.selectCustomerDiv {
  display: flex;
  flex-direction: column;
  .header {
    font-size: small;
    margin-bottom: 5px;
  }
  .field {
    font-weight: 600;
  }
}
.no-details-section {
  display: flex;
  justify-content: center;
  font-weight: 600;
  margin: 2rem;
}
</style>
