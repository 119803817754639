<script setup lang="ts">
import NotFound from '@/assets/not_found.svg'
import { onMounted, ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import type { Pigmy } from '@/api/models/pigmy/Pigmy'
import CustomButton from '@/components/CustomComponent/CustomButton.vue'
import LoadingComponent from '@/components/LoadingComponent.vue'
import ApiService from '@/api/ApiService'
import router from '@/router'
import Storage from '@/utils/Storage'
import type { ToastMessageOptions } from 'primevue/toast'
import type { PendingPigmy } from '@/api/models/pigmy/PendingPigmy'
import ConversionUtility from '@/utils/ConversionUtility'
import type { ApprovePigmyRequest } from '@/api/models/pigmy/ApprovePigmyRequest'

const toast = useToast()
const isLoading = ref<boolean>(false)
const pendingPigmy = ref<PendingPigmy[]>([])
const approvedPigmy = ref<PendingPigmy[]>([])
const displayPigmies = ref<PendingPigmy[]>([])
const total = ref<number>(0)
const options = ref<string[]>(['Pending', 'Approved'])
const selectedOption = ref<string>('Pending')

onMounted(() => {
  isLoading.value = true
  ApiService.getPendingPigmies()
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        response.data.forEach((pigmy) => {
          if (pigmy.status == 'CREATED') {
            pendingPigmy.value.push(pigmy)
            total.value += pigmy.approvalAmount
          }
          if (pigmy.status == 'APPROVED') {
            approvedPigmy.value.push(pigmy)
          }
        })
      } else {
        showToast('Failed to fetch Pigmies', 'Something went wrong', 'error')
      }
    })
    .catch((error) => {
      showToast('Failed to fetch Pigmy', error.error, 'error')
    })
    .finally(() => {
      isLoading.value = false
      selectChange()
    })
})

function selectChange() {
  if (selectedOption.value == 'Approved') {
    displayPigmies.value = approvedPigmy.value
  }
  if (selectedOption.value == 'Pending') {
    displayPigmies.value = pendingPigmy.value
  }
}

function approvePigmy(data: PendingPigmy) {
  isLoading.value = true
  const approvePigmyRequest: ApprovePigmyRequest = {
    approvalId: data.id.toString(),
    approvedAt: Date.now().toString()
  }

  ApiService.approvePigmy(approvePigmyRequest)
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        pendingPigmy.value = pendingPigmy.value.filter((pigmy) => pigmy.id != data.id)
        displayPigmies.value = displayPigmies.value.filter((pigmy) => pigmy.id != data.id)
        total.value -= data.approvalAmount
        showToast('Pigmy approved successfully', 'Pigmy approved successfully', 'success')
      } else {
        showToast('Failed to approve Pigmy', 'Something went wrong', 'error')
      }
    })
    .catch((error) => {
      showToast('Failed to approve Pigmy', error.error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}
</script>
<template>
  <main class="main">
    <Card class="searchCard" v-if="!isLoading">
      <template #content>
        <Toast class="error-p-toast" />
        <div v-if="!displayPigmies && !isLoading" class="notFound">
          <Image :src="NotFound" width="800" />
          <Chip label="No pigmy found for the provided search query!" style="font-weight: 600" />
        </div>
        <div v-if="displayPigmies && !isLoading">
          <Panel header="Pigmy Status">
            <div class="pigmy-approval-container">
              <SelectButton
                :allow-empty="false"
                v-model="selectedOption"
                :options="options"
                @change="selectChange"
              />
              <Fieldset>
                <DataTable
                  v-if="
                    displayPigmies[0] && selectedOption == 'Pending'
                      ? pendingPigmy[0]
                      : approvedPigmy[0]
                  "
                  :value="selectedOption == 'Approved' ? approvedPigmy : pendingPigmy"
                >
                  <Column field="empId" header="Employee ID" />
                  <Column field="startDate" header="Started At">
                    <template #body="slotProps">
                      {{ ConversionUtility.toDateAndTimeFormat(slotProps.data.startDate) }}
                    </template>
                  </Column>
                  <Column field="endDate" header="Ends At">
                    <template #body="slotProps">
                      {{ ConversionUtility.toDateAndTimeFormat(slotProps.data.endDate) }}
                    </template>
                  </Column>
                  <Column field="approvalAmount" header="Approval Amount">
                    <template #body="slotProps">
                      {{
                        ConversionUtility.toStringRupeesConversion(slotProps.data.approvalAmount)
                      }}
                    </template>
                  </Column>
                  <Column header="Status" style="width: 5%">
                    <template #body="slotProps">
                      <CustomButton
                        title="Approve"
                        icon="check"
                        @click="approvePigmy(slotProps.data)"
                      />
                    </template>
                  </Column>
                  <template #footer>
                    <div class="pigmy-approval-container-footer">
                      <p class="total" v-if="selectedOption == 'Pending'">
                        Total:{{ ConversionUtility.toStringRupeesConversion(total) }}
                      </p>
                    </div>
                    <Tag severity="secondary" v-if="selectedOption == 'Pending'">
                      After Approval Pigmies will be added to the Approved section.
                    </Tag>
                  </template>
                </DataTable>
                <Tag v-else severity="secondary">No Transactions Found</Tag>
              </Fieldset>
            </div>
          </Panel>
        </div>
      </template>
    </Card>
    <LoadingComponent text="Please wait while we process the request!" :dialogVisiblity="isLoading" />
  </main>
</template>
<style scoped>
.pigmy-approval-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .pigmy-approval-container-footer {
    display: flex;
    flex-direction: column;
    align-items: end;
    .total {
      font-size: large;
      font-weight: 600;
    }
  }
  .end-button {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
}
</style>
