<script setup lang="ts">
import SearchComponent from '@/components/SearchComponent.vue'
import NotFound from '@/assets/not_found.svg'
import { ref } from 'vue'
import type { ToastMessageOptions } from 'primevue/toast'
import { useToast } from 'primevue/usetoast'
import ValidationUtility from '@/utils/ValidationUtility'
import type { ShareholderWithID } from '@/api/models/shareholder/shareholder'
import ApiService from '@/api/ApiService'
import Storage from '@/utils/Storage'
import router from '@/router'
import type { CustomerDetailsRequest } from '@/api/models/customer/CustomerDetailsRequest'
import type { CustomerDetailsData } from '@/api/models/customer/CustomerDetailsData'
import LoadingComponent from '@/components/LoadingComponent.vue'
import type { CreatedBy } from '@/api/models/CreatedBy'
import ConversionUtility from '@/utils/ConversionUtility'
import type { CreateShareHolderRequest } from '@/api/models/shareholder/CreateShareHolderRequest'
import CustomButton from '@/components/CustomComponent/CustomButton.vue'
import type { ShareholderTransactions } from '@/api/models/shareholder/ShareholderTransactions'
import type { GetShareholderTransactionsRequest } from '@/api/models/shareholder/GetShareholderTransactionsRequest'

const isLoading = ref<boolean>(false)
const toast = useToast()
const shareholder = ref<ShareholderWithID>()
const customer = ref<CustomerDetailsData>()
const shareholderAmount = ref<number>()
const isShareholder = ref<boolean>(false)
const voucherNo= ref<number>()
const isTransactionsVisible = ref<boolean>(false)
const transactions = ref<ShareholderTransactions[]>([])

function handelSearchCustomer(search: string) {
  customer.value = undefined
  shareholder.value = undefined
  voucherNo.value = undefined
  transactions.value = []
  isTransactionsVisible.value = false
  if (!ValidationUtility.validateNumber(search)) {
    shareholder.value = undefined
    showToast('Invalid Customer ID', 'Please provide a valid Customer ID!', 'error')
    return
  }
  if (search.length < 6) {
    showToast('Invalid Customer ID', 'Customer ID Should be 6 characters!', 'error')
    return
  }
  isLoading.value = true
  const searchCustomer: CustomerDetailsRequest = {
    cusId: search
  }
  ApiService.getCustomerDetails(searchCustomer)
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        customer.value = response.data
        if (customer.value.shareholder) {
          shareholder.value = customer.value.shareholder
          isShareholder.value = true
        }
      } else {
        showToast('Failed to fetch', response.error ?? 'Something went wrong', 'error')
      }
    })
    .catch((error) => {
      showToast('Failed to fetch', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function handleCreateShareholder() {
  if (!shareholderAmount.value) {
    showToast('Shareholder Amount is blank/empty', 'Creating Shareholder failed!', 'error')
    return
  }

  if(!voucherNo.value) {
    showToast('Invalid voucher number', 'Please enter voucher number', 'error')
    return
  }

  if(voucherNo.value.toString().length > 8 ) {
    showToast('Invalid voucher number', 'Voucher number should be less than 8 digits!', 'error')
    return    
  }
  isLoading.value = true
  const createBy: CreatedBy = {
    empId: Storage.getEmpId()!!,
    createdAt: Date.now()
  }

  const shareHolderTransactions: ShareholderTransactions = {
    openingBalance: 0,
    deposit: ConversionUtility.toPaiseConversion(shareholderAmount.value),
    withdrawal: 0,
    closingBalance: ConversionUtility.toPaiseConversion(shareholderAmount.value),
    createdAt: Date.now(),
    createdBy: createBy,
    voucherNo: voucherNo.value,
    desc: 'Opening balance for shareholder'
  }


  const createShareholderRequest: CreateShareHolderRequest = {
    customerId: Number(customer.value?.customer?.customerId),
    shareAmount: ConversionUtility.toPaiseConversion(shareholderAmount.value),
    createdAt: Date.now(),
    createdBy: createBy,
    transactions: [shareHolderTransactions]
  }

  ApiService.createShareholder(createShareholderRequest)
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        showToast(
          'Shareholder Created Successfully',
          'Shareholder with ID ' + response.data.id + ' created',
          'success'
        )
        handelSearchCustomer(customer.value?.customer?.customerId.toString()!!)
      } else {
        showToast('Failed to create Shareholder', 'error', 'error')
      }
    })
    .catch((error) => {
      showToast('Failed to create Shareholder', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
      shareholderAmount.value = undefined
    })
}


function getTransactions() {
  if (!customer.value?.shareholder) {
    showToast('Invalid loan', 'Please select a valid loan', 'error')
    return
  }
  isLoading.value = true
  const getTransactions: GetShareholderTransactionsRequest = { shareholderId: customer.value.shareholder?.id.toString() }
  ApiService.shareholderTransactions(getTransactions)
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        transactions.value = response.data
        isTransactionsVisible.value = true
      } else {
        showToast('Failed to fetch EMI transactions', response.error ?? 'No error response!', 'error')
      }
    })
    .catch((error) => {
      showToast('Failed to fetch EMI transactions', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
} 

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}
</script>

<template>
  <main class="main">
    <Card class="search-card">
      <template #content>
        <Toast class="error-p-toast" />
        <LoadingComponent
          text="Please wait while we process the request!"
          :dialogVisiblity="isLoading"
        />
        <SearchComponent
          title="Search Shareholders"
          subtitle="Search for Shareholders using Customer ID. (eg - 100001)."
          placeholder="Search Shareholders"
          @search-click="handelSearchCustomer"
        />
        <div v-if="shareholder && !isLoading" class="cont">
          <h2 class="title text-3xl font-bold">Shareholder Details :</h2>

          <div class="holder-details">
            <div>
              <span class="text-heading">Customer Id: </span>
              <span class="text">{{ shareholder.customerId }}<br /></span>
            </div>
            <div>
              <span class="text-heading">Name: </span>
              <span class="text">{{ customer?.customer?.firstName  }} {{ customer?.customer?.middleName ?? '' }} {{ customer?.customer?.lastName  }}<br /></span>
            </div>
            <div>
              <span class="text-heading">ID: </span>
              <span class="text">{{ shareholder.id }}<br /></span>
            </div>
            <div>
              <span class="text-heading">Shareholder Amount: </span>
              <span class="text"
                >{{ ConversionUtility.toStringRupeesConversion(shareholder.shareAmount) }}<br
              /></span>
            </div>
            <div>
              <span class="text-heading">Created On: </span>
              <span class="text"
                >{{ ConversionUtility.toDateFormat(shareholder.createdAt) }}<br
              /></span>
            </div>
          </div>
          <div class="operations">
            <CustomButton
            title="view transactions"
            icon="book"
            @click="getTransactions"
            />
          </div>
        </div>
        <div v-if="customer && !isLoading && !shareholder" class="cont">
          <h2 class="title text-3xl font-bold">Create Shareholder Accounts :</h2>
          <div class="holder-details">
            <div>
              <span class="text-heading">Customer Id: </span>
              <span class="text">{{ customer.customer?.customerId }}<br /></span>
            </div>
            <div>
              <span class="text-heading">Name: </span>
              <span class="text">{{ customer?.customer?.firstName  }} {{ customer?.customer?.middleName ?? '' }} {{ customer?.customer?.lastName  }}<br /></span>
            </div>

            <div>
              <span class="text-heading">Created On: </span>
              <span class="text"
                >{{ ConversionUtility.toDateFormat(customer.customer?.createdAt!!) }}<br
              /></span>
            </div>
          </div>
          <div class="operations">
            <div class="button-transactions">
              <InputNumber
                v-model="shareholderAmount"
                b
                class="input-text"
                placeholder="Shareholder Amount"
                mode="currency"
                currency="INR"
                :min-fraction-digits="0"
                :min="0"
                fluid
                locale="en-IN"
              />
              <InputNumber
                v-model="voucherNo"
                :useGrouping="false"
                class="input-text"
                :min="1"
                placeholder="Voucher Number"
              />
            </div>
            <CustomButton icon="plus" title="Create Shareholder" @click="handleCreateShareholder" />
          </div>
        </div>
        <div v-if="isTransactionsVisible" class="cont">
          <DataTable :value="transactions">
            <Column header="Created On">
              <template #body="slotProps">
                {{ ConversionUtility.toDateFormat(slotProps.data.createdAt) }}
              </template>
            </Column>
            <Column header="Description" field="desc"/>
            <Column header="Opening balance">
              <template #body="slotProps">
                {{ ConversionUtility.toStringRupeesConversion(slotProps.data.openingBalance) }}
              </template>
            </Column>
            <Column header="Deposit">
              <template #body="slotProps">
                {{ ConversionUtility.toStringRupeesConversion(slotProps.data.deposit??0) }}
              </template>
            </Column>
            <Column header="Withdraw">
              <template #body="slotProps">
                {{ ConversionUtility.toStringRupeesConversion(slotProps.data.withdrawal??0) }}
              </template>
            </Column>
            <Column header="Closing balance">
              <template #body="slotProps">
                {{ ConversionUtility.toStringRupeesConversion(slotProps.data.closingBalance) }}
              </template>
            </Column>
          </DataTable>
        </div>
        <div v-if="!shareholder && !customer && !isLoading" class="notFound">
          <Image :src="NotFound" width="800" />
          <Chip label="No shareholders found for the provided search query!" style="font-weight: 600" />
        </div>
      </template>
    </Card>
  </main>
</template>
<style scoped>
.title {
  text-align: start;
  margin: 0rem 1.5rem 1.5rem;
}
</style>
