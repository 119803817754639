<script setup lang="ts">
import ApiService from '@/api/ApiService'
import NotFound from '@/assets/not_found.svg'
import type { Loan } from '@/api/models/loan/Loan'
import type { LoanStatusRequest } from '@/api/models/loan/LoanStatusRequest'
import LoadingComponent from '@/components/LoadingComponent.vue'
import SearchComponent from '@/components/SearchComponent.vue'
import type { ToastMessageOptions } from 'primevue/toast'
import { useToast } from 'primevue/usetoast'
import { ref } from 'vue'
import ConversionUtility from '@/utils/ConversionUtility'
import type { SelectModel } from '@/models/SelectModel'
import router from '@/router'
import Storage from '@/utils/Storage'

const toast = useToast()
const isLoading = ref<boolean>(false)
const loan = ref<Loan>()
const activeStep= ref<string>()
const loanStatus = ref<SelectModel[]>([
  { value: 'PENDING',label:'1' },
  { value: 'APPROVED',label:'2' },
  { value: 'DISBURSED',label:'3' },
  { value: 'REJECTED',label:'4' }
])
function handelSearchLoanAccount(query: string) {
  loan.value = undefined
  activeStep.value = undefined
  if (query.length < 6) {
    showToast('Invalid search query', 'Customer ID is at least 6 characters long', 'error')
    return
  }
  isLoading.value = true
  const searchCustomer: LoanStatusRequest = { loanApplicationId: query }
  ApiService.getLoanStatus(searchCustomer)
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({ name: 'login' })

        }
      }, 2000)
      if (response.statusCode == 200) {
        loan.value = response.data
        loanStatus.value.filter((item)=>{
          if(item.value == loan.value?.status){
            activeStep.value = item.label
          }
        })
      } else {
        showToast('Failed to fetch customers', response.error ?? 'No error response!', 'error')
      }
    })
    .catch((error) => {
      showToast('Failed to fetch customers', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}
</script>
<template>
  <main class="main">
    <Card class="search-card">
      <template #content>
        <Toast class="error-p-toast" />
        <SearchComponent
          title="Search Loan Application Status"
          subtitle="Search for Loans Status using Application ID."
          placeholder="Search Loan status"
          @search-click="handelSearchLoanAccount"
        />
        <div v-if="isLoading">
          <LoadingComponent
            text="Please wait while we process the request!"
            :dialogVisiblity="isLoading"
          />
        </div>
        <div v-else-if="!loan && !isLoading" class="notFound">
          <Image :src="NotFound" width="800" />
          <Chip label="No accounts found for the provided search query!" style="font-weight: 600" />
        </div>
        <div class="cont" v-if="loan && !isLoading">
          <div class="holder-details">
            <div>
              <span class="text-heading">Application Id: </span>
              <span class="text">{{ loan.applicationId }}</span>
            </div>
            <div>
              <span class="text-heading">Customer ID: </span>
              <span class="text">{{ loan.customerId }}</span>
            </div>
            <div v-if="loan.approvalDate">
              <span class="text-heading">Approved On: </span>
              <span class="text">{{
                ConversionUtility.toDateAndTimeFormat(loan.approvalDate)
              }}</span>
            </div>
            <div v-if="loan.rejectionDate">
              <span class="text-heading">Approved On: </span>
              <span class="text">{{
                ConversionUtility.toDateAndTimeFormat(loan.rejectionDate)
              }}</span>
            </div>
            <div v-if="loan.disbursalDate">
              <span class="text-heading">Approved On: </span>
              <span class="text">{{
                ConversionUtility.toDateAndTimeFormat(loan.disbursalDate)
              }}</span>
            </div>
            <div>
              <span class="text-heading">Created On: </span>
              <span class="text">{{ ConversionUtility.toDateFormat(loan.createdAt) }}</span>
            </div>
            <div>
              <span class="text-heading">Loan Status: </span>
              <span class="text">{{ loan.status }}</span>
            </div>
          </div>
          <Divider style="margin-top: 0" />
          <Stepper :value="activeStep" linear>
            <StepList value="1">
              <Step v-for="status in loanStatus" :key="status.value" :value="status.label" :style="loan.status == status.value ? 'color: #000' : ''">{{ status.value }}</Step>
            </StepList>
          </Stepper>
        </div>
      </template>
    </Card>
  </main>
</template>
<style scoped>
h2 {
  text-align: start;
  margin: 0.5rem 0 1.5rem 1.5rem;
}
</style>
