import { ref } from 'vue'

export default class ValidationUtility {
  static validateEmail(email: string) {
    if (
      String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      return true
    } else {
      return false
    }
  }

  //Checks wether the email has only . _ - (these special character must not be at 0th position if(false))
  static checkEmail(email: string){
    if(String(email)
    .toLowerCase()
  .match(
    /^[a-zA-Z]+[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  )){
    return true
  }
    
  else{
    return false
  }
  }


  static validateString(string: string) {
    const regex = /^[a-zA-Z]+$/
    if (regex.test(string)) {
      return true
    } else {
      return false
    }
  }
  
  static validateSelect(select: string) {
    if (select == '') {
      return false
    } else {
      return true
    }
  }

  static validateNumber(number: string) {
    const regex = /^[0-9]+$/
    if (regex.test(number)) {
      return true
    } else {
      return false
    }
  }
}
