<script setup lang="ts">
import ApiService from '@/api/ApiService'
import type { ApproveLoanRequest } from '@/api/models/loan/ApproveLoanRequest'
import type { Loan } from '@/api/models/loan/Loan'
import type { LoanDisburseRequest } from '@/api/models/loan/LoanDisburseRequest'
import type { RejectLoanRequest } from '@/api/models/loan/RejectLoanRequest'
import CustomButton from '@/components/CustomComponent/CustomButton.vue'
import LoadingComponent from '@/components/LoadingComponent.vue'
import router from '@/router'
import ConversionUtility from '@/utils/ConversionUtility'
import DateUtils from '@/utils/DateUtils'
import Storage from '@/utils/Storage'
import type { ToastMessageOptions } from 'primevue/toast'
import { useConfirm } from 'primevue/useconfirm'
import { useToast } from 'primevue/usetoast'
import { onMounted, ref } from 'vue'

const toast = useToast()
const isLoading = ref<boolean>(false)
const confirmDialog = useConfirm()

const options = ref<string[]>(['Pending', 'Approved'])
const selectedOption = ref<string>('Pending')
const displayLoans = ref<Loan[]>([])
const selectedLoan = ref<Loan>()
const voucherNo = ref<number>()

onMounted(() => {
  getPendingLoans()
})

function getPendingLoans() {
  isLoading.value = true
  ApiService.getPendingLoans()
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        displayLoans.value = response.data
      } else {
        displayLoans.value = []
        showToast('Failed to fetch loans', response.error ?? 'Something went wrong', 'error')
      }
    })
    .catch((error) => {
      displayLoans.value = []
      showToast('Failed to fetch loans', error.error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function getApprovedLoans() {
  isLoading.value = true
  ApiService.getApprovedLoans()
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        displayLoans.value = response.data
      } else {
        displayLoans.value = []
        showToast('Failed to fetch loans', response.error ?? 'Something went wrong', 'error')
      }
    })
    .catch((error) => {
      displayLoans.value = []
      showToast('Failed to fetch loans', error.error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}

function handelSelectChange() {
  if (selectedOption.value == 'Approved') {
    getApprovedLoans()
  } else {
    getPendingLoans()
  }
}

function handelApproveLoan() {
  confirmDialog.require({
    message: `Are you sure you want to approve loan account ${selectedLoan.value?.applicationId}?`,
    header: 'Confirmation to proceed',
    icon: 'fas fa-exclamation-triangle',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Yes, proceed'
    },
    accept() {
      if (!selectedLoan.value) {
        showToast('Please select a loan', 'Select a loan', 'error')
        return
      }
      isLoading.value = true
      const request: ApproveLoanRequest = {
        loanApplicationId: selectedLoan.value.applicationId,
        approvedDate: new Date().getTime().toString()
      }
      ApiService.approveLoan(request)
        .then((response) => {
          setTimeout(() => {
            if (response.statusCode == 401) {
              Storage.clearData()
              router.push({
                name: 'login'
              })
            }
          }, 2000)
          if (response.statusCode == 200) {
            showToast('Loan approved successfully', 'Loan approved successfully', 'success')
            getPendingLoans()
          } else {
            showToast('Failed to approve loan', response.error ?? 'Something went wrong', 'error')
          }
        })
        .catch((error) => {
          showToast('Failed to approve loan', error.error, 'error')
        })
        .finally(() => {
          isLoading.value = false
        })
    },
    reject() {}
  })
}

function handelRejectLoan() {
  confirmDialog.require({
    message: `Are you sure you want to reject loan account ${selectedLoan.value?.applicationId}?`,
    header: 'Confirmation to proceed',
    icon: 'fas fa-exclamation-triangle',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Yes, proceed'
    },
    accept() {
      if (!selectedLoan.value) {
        showToast('Please select a loan', 'Select a loan', 'error')
        return
      }
      isLoading.value = true
      const request: RejectLoanRequest = {
        loanApplicationId: selectedLoan.value.applicationId,
        rejectionDate: new Date().getTime().toString()
      }
      ApiService.rejectLoan(request)
        .then((response) => {
          setTimeout(() => {
            if (response.statusCode == 401) {
              Storage.clearData()
              router.push({
                name: 'login'
              })
            }
          }, 2000)
          if (response.statusCode == 200) {
            showToast('Loan rejection successfully', 'Loan rejected successfully', 'success')
            getPendingLoans()
          } else {
            showToast('Failed to reject loan', response.error ?? 'Something went wrong', 'error')
          }
        })
        .catch((error) => {
          showToast('Failed to reject loan', error.error, 'error')
        })
        .finally(() => {
          isLoading.value = false
        })
    },
    reject() {}
  })
}

function handelDisburseLoan() {
  confirmDialog.require({
    message: `Are you sure you want to disburse loan account of ${ConversionUtility.toStringRupeesConversion(
      selectedLoan.value?.amountToTransferToSB!!
    )}?`,
    header: 'Confirmation to proceed',
    icon: 'fas fa-exclamation-triangle',
    rejectProps: {
      label: 'Cancel',
      severity: 'secondary',
      outlined: true
    },
    acceptProps: {
      label: 'Yes, proceed'
    },
    accept() {
      if (!selectedLoan.value) {
        showToast('Please select a loan', 'Select a loan', 'error')
        return
      }
      if (!voucherNo.value) {
        showToast('Please enter voucher number', 'Please enter voucher number', 'error')
        return
      }
      if (voucherNo.value.toString().length > 8) {
        showToast(
          'Please enter a valid voucher number',
          'Please enter a valid voucher number',
          'error'
        )
        return
      }
      isLoading.value = true
      const request: LoanDisburseRequest = {
        loanApplicationId: selectedLoan.value.applicationId,
        disbursalDate: new Date().getTime(),
        passbookVoucherNo: voucherNo.value,
        shareholderVoucherNo: voucherNo.value
      }
      ApiService.disburseLoan(request)
        .then((response) => {
          setTimeout(() => {
            if (response.statusCode == 401) {
              Storage.clearData()
              router.push({
                name: 'login'
              })
            }
          }, 2000)
          if (response.statusCode == 200) {
            showToast(`Loan Disbursement successfully for loan account ${response.data}`, 'Loan disbursement successfully', 'success')
            if(selectedOption.value=='Pending') {
              getPendingLoans()
            }
            else{
              getApprovedLoans()
            }
          } else {
            showToast('Failed to disburse loan', response.error ?? 'Something went wrong', 'error')
          }
        })
        .catch((error) => {
          showToast('Failed to disburse loan', error.error, 'error')
        })
        .finally(() => {
          isLoading.value = false
          voucherNo.value = undefined
        })
    },
    reject() {
      voucherNo.value = undefined
    }
  })
}

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}
</script>
<template>
  <main class="main">
    <Card class="search-card">
      <template #content>
        <ConfirmDialog :draggable="false" :closable="false" />
        <div v-if="isLoading">
          <LoadingComponent
            text="Please wait while we process the request!"
            :dialogVisiblity="isLoading"
          />
        </div>
        <Panel header="Approve Loans">
          <div class="loan-approve-container">
            <Toast class="error-p-toast" />
            <SelectButton
              :allow-empty="false"
              v-model="selectedOption"
              :options="options"
              v-on:change="handelSelectChange"
            />
            <Divider style="margin: 0" />
            <div v-if="displayLoans.length > 0 && !isLoading">
              <Card v-for="loan in displayLoans" :key="loan.applicationId" class="cont">
                <template #header>
                  <div class="holder-details">
                    <div>
                      <span class="text-heading">Application ID: </span>
                      <span class="text">{{ loan.applicationId }}<br /></span>
                    </div>
                    <div>
                      <span class="text-heading">Principal Amount: </span>
                      <span class="text"
                        >{{ ConversionUtility.toStringRupeesConversion(loan.loanAmount) }}<br
                      /></span>
                    </div>
                    <div>
                      <span class="text-heading">Customer ID: </span>
                      <span class="text">{{ loan.customerId }}<br /></span>
                    </div>
                    <div>
                      <span class="text-heading">Created On: </span>
                      <span class="text"
                        >{{ ConversionUtility.toDateFormat(loan.createdAt) }}<br
                      /></span>
                    </div>
                    <div>
                      <span class="text-heading">Status: </span>
                      <Tag v-if="loan.status == 'APPROVED'" severity="success">
                        {{ loan.status }}
                      </Tag>
                      <Tag v-else-if="loan.status == 'REJECTED'" severity="danger">
                        {{ loan.status }}
                      </Tag>
                      <Tag v-else severity="warn">
                        {{ loan.status }}
                      </Tag>
                    </div>
                    <div>
                      <span class="text-heading">Type: </span>
                      <span class="text">{{ loan.loanType }}<br /></span>
                    </div>
                  </div>
                  <Divider style="margin-top: 0" />
                </template>
                <template #content>
                  <section class="loan-approve-container">
                    <Panel header="Loan Details" toggleable collapsed>
                      <Divider style="margin-top: 0" />
                      <div class="selectLoanSection">
                        <div>
                          <span class="text-heading">Tenure: </span>
                          <span class="text">
                            {{
                              Math.floor(loan.tenureMonths / 12) > 0
                                ? Math.floor(loan.tenureMonths / 12) +
                                  (Math.floor(loan.tenureMonths / 12) === 1
                                    ? ' Year ~ '
                                    : ' Years ~ ') +
                                  (loan.tenureMonths % 12) +
                                  ' Months'
                                : (loan.tenureMonths % 12) + ' Months'
                            }}<br />
                          </span>
                        </div>
                        <div>
                          <span class="text-heading">Loan End Date: </span>
                          <span class="text"
                            >{{
                              loan.tenureMonths
                            ? new Date(
                                DateUtils.getFutureDateInMilliseconds(loan.emiDate!!,loan.tenureMonths+1,new Date(loan.createdAt!!))
                              ).toDateString()
                            : '-'
                            }}<br
                          /></span>
                        </div>
                        <div>
                          <span class="text-heading">Interest Rate: </span>
                          <span class="text">{{ loan.interestRate }} %<br /></span>
                        </div>
                        <div>
                          <span class="text-heading">Created By: </span>
                          <span class="text">{{ loan.createdBy.empId }}<br /></span>
                        </div>
                        <div style="display: flex; flex-direction: row; align-items: center">
                          <span class="text-heading">Guarantors : </span>
                          <span v-for="guarantor in loan.guarantors" class="text chip-container m-2"
                            ><Chip>{{ guarantor }}</Chip></span
                          >
                        </div>
                        <div style="display: flex; flex-direction: row; align-items: center">
                          <span class="text-heading">Directors Recommendation : </span>
                          <span class="text chip-container m-2"
                            ><Chip>{{ loan.directorId >0 ? loan.directorId : ` -` }}</Chip></span
                          >
                        </div>
                      </div>
                    </Panel>
                    <Panel header="Amount Details" toggleable collapsed>
                      <Divider style="margin-top: 0" />
                      <div class="flex flex-col gap-5">
                        <Panel class="flex flex-col">
                          <span class="text-base flex">Total Deductions :</span>
                          <Divider />
                          <div class="calculation-container">
                            <span class="text-heading">Principal Amount: </span>
                            <span class="text"
                              >{{ ConversionUtility.toStringRupeesConversion(loan.loanAmount) }}<br
                            /></span>
                          </div>
                          <div class="calculation-container">
                            <span class="text-heading"
                              >Shareholder Transfer Amount @ ({{ loan.shareTransferPercentage }} %)
                            </span>
                            <span class="text"
                              >-
                              {{
                                ConversionUtility.toStringRupeesConversion(
                                  loan.shareHolderTransferAmount
                                )
                              }}<br
                            /></span>
                          </div>
                          <div class="calculation-container">
                            <span class="text-heading">Processing Fees: </span>
                            <span class="text"
                              >- {{ ConversionUtility.toStringRupeesConversion(loan.processingFees)
                              }}<br
                            /></span>
                          </div>
                          <div class="calculation-container">
                            <span class="text-heading">Building Fees: </span>
                            <span class="text"
                              >- {{ ConversionUtility.toStringRupeesConversion(loan.buildingFees)
                              }}<br
                            /></span>
                          </div>
                          <div class="calculation-container">
                            <span class="text-heading">Miscellaneous Fees: </span>
                            <span class="text"
                              >- {{ ConversionUtility.toStringRupeesConversion(loan.miscFees)
                              }}<br
                            /></span>
                          </div>
                          <div class="calculation-container">
                            <span class="text-heading">Share Fees: </span>
                            <span class="text"
                              >- {{ ConversionUtility.toStringRupeesConversion(loan.shareFees)
                              }}<br
                            /></span>
                          </div>
                          <Divider />
                          <div class="calculation-container">
                            <span class="text-heading">Transfer To SB: </span>
                            <span class="text"
                              >{{
                                ConversionUtility.toStringRupeesConversion(
                                  loan.amountToTransferToSB
                                )
                              }}<br
                            /></span>
                          </div>
                        </Panel>
                        <Panel class="flex flex-col">
                          <span class="text-base flex">Total Loan Amount:</span>
                          <Divider />
                          <div class="calculation-container">
                            <span class="text-heading">Principal Amount (A): </span>
                            <span class="text"
                              >{{ ConversionUtility.toStringRupeesConversion(loan.loanAmount) }}<br
                            /></span>
                          </div>
                          <div class="calculation-container">
                            <span class="text-heading"
                              >Total Interest Amount @ {{ loan.interestRate }} % (B):
                            </span>
                            <span class="text"
                              >{{ ConversionUtility.toStringRupeesConversion(loan.interestAmount)
                              }}<br
                            /></span>
                          </div>
                          <Divider />
                          <div class="calculation-container">
                            <span class="text-heading">Total Payable Amount (A + B): </span>
                            <span class="text"
                              >{{
                                ConversionUtility.toStringRupeesConversion(
                                  loan.loanAmount + loan.interestAmount
                                )
                              }}<br
                            /></span>
                          </div>
                        </Panel>
                      </div>
                    </Panel>
                    <Panel header="Customer Details" collapsed toggleable>
                      <Divider style="margin-top: 0" />
                      <div class="selectLoanSection">
                        <div>
                          <span class="text-heading">Customer ID: </span>
                          <span class="text">{{ loan.customerId }}<br /></span>
                        </div>
                        <div>
                          <span class="text-heading"
                            >{{
                              loan.employerName
                                ? `Employer Name`
                                : loan.businessDetails
                                  ? `Business Details`
                                  : loan.agricultureDetails
                                    ? `Agriculture Details`
                                    : `Something Went Wrong`
                            }}:
                          </span>
                          <span class="text"
                            >{{
                              loan.employerName ?? loan.businessDetails ?? loan.agricultureDetails
                            }}<br
                          /></span>
                        </div>
                        <div>
                          <span class="text-heading">Income Per Annum: </span>
                          <span class="text"
                            >{{ ConversionUtility.toStringRupeesConversion(loan.incomePerAnnum)
                            }}<br
                          /></span>
                        </div>
                        <div v-if="loan.incomePerMonth">
                          <span class="text-heading">Income Per Month: </span>
                          <span class="text"
                            >{{ ConversionUtility.toStringRupeesConversion(loan.incomePerMonth)
                            }}<br
                          /></span>
                        </div>
                        <div>
                          <span class="text-heading">Collateral Details: </span>
                          <span class="text">{{ loan.collateralDetails }}<br /></span>
                        </div>
                        <div style="display: flex; flex-direction: column; align-items: center">
                          <span class="text-heading">Documents Submitted: </span>
                          <span
                            class="text chip-container"
                            style="
                              flex-direction: column;
                              justify-content: center;
                              align-items: center;
                            "
                            ><Chip v-for="doc in loan.documentSubmitted" class="m-2">{{ doc }}</Chip
                            ><br
                          /></span>
                        </div>
                      </div>
                    </Panel>
                  </section>
                  <div class="operations" v-if="loan.status === 'PENDING'">
                    <CustomButton
                      title="Reject Loan"
                      icon="close"
                      severity="danger"
                      @click="
                        () => {
                          selectedLoan = loan
                          handelRejectLoan()
                        }
                      "
                    />
                    <CustomButton
                      title="Approve Loan"
                      icon="check"
                      severity="success"
                      @click="
                        () => {
                          selectedLoan = loan
                          handelApproveLoan()
                        }
                      "
                    />
                  </div>
                  <div class="operations" v-if="loan.status === 'APPROVED'">
                    <InputNumber
                      :min="1"
                      v-model="voucherNo"
                      :use-grouping="false"
                      placeholder="Voucher No"
                      class="w-1/4"
                    />
                    <CustomButton
                      title="Disburse Loan"
                      icon="times"
                      @click="
                        () => {
                          selectedLoan = loan
                          handelDisburseLoan()
                        }
                      "
                    />
                  </div>
                </template>
              </Card>
            </div>
            <Tag v-else severity="secondary">No Transactions Found</Tag>
          </div>
        </Panel>
      </template>
    </Card>
  </main>
</template>
<style scoped>
.loan-approve-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.selectLoanSection {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  row-gap: 1.5rem;
  column-gap: 0rem;
}
.operations {
  margin-top: 2rem;
  justify-content: end;
  gap: 1rem;
}
.cont {
  border-radius: 10px;
  border: 0.5px rgb(224, 224, 224) solid;
}
.chip-container {
  gap: 0.5rem;
}
.calculation-container {
  display: flex;
  justify-content: space-between;
}
.text-heading {
  font-size: medium;
}
</style>
