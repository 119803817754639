<script setup lang="ts">
import LoadingComponent from '@/components/LoadingComponent.vue';
import Card from 'primevue/card';
import { ref, watch } from 'vue';
import CustomButton from '@/components/CustomComponent/CustomButton.vue';
import type { ToastMessageOptions } from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import type { ReportRequest } from '@/api/models/reports/ReportRequest';
import ApiService from '@/api/ApiService';
import ConversionUtility from '@/utils/ConversionUtility';
import Storage from '@/utils/Storage';
import router from '@/router';
import * as XLSX from 'xlsx'
import type { SavingsReport } from '@/api/models/reports/SavingsReport';

const loadingProgress = ref<number>(50)
const toast = useToast()
const minDate = ref(new Date(2024, 8, 1))
const maxDate = ref(new Date())
const fromDate = ref<Date>()
const toDate = ref<Date | null>()
const isLoading = ref<boolean>(false)
const FDate = ref()
const EDate = ref()
const reportDetails = ref<SavingsReport>()

function viewReports(){
    if(!fromDate.value){
        showToast("From Date cannot be empty", "Please Select From Date", 'error')
        return
    }

    if(!toDate.value){
        showToast("To Date cannot be empty", "Please Select To Date", 'error')
        return
    }
    isLoading.value = true

    FDate.value = new Date(fromDate.value).setHours(0, 0, 0, 0);
    EDate.value = new Date(toDate.value).setHours(23, 59, 59, 999);
    
    const savingsRequest : ReportRequest ={
      startDate: FDate.value,
      endDate: EDate.value
    }

    ApiService.savingsReport(savingsRequest)
    .then(
      (response) => {
        setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
        if(response.statusCode == 200){
          reportDetails.value = response.data
        }
      }
    )
    .catch((error) => {
      showToast("Settings",error, "error")
    })
    .finally(() => {
      isLoading.value = false
    })
}

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}

function download(){
  const reportData = [
  ["Savings Bank Account Reports"],
  [],
  ["From:", ConversionUtility.toDateFormat(reportDetails.value?.startDate!!)], 
  ["To:", ConversionUtility.toDateFormat(reportDetails.value?.endDate!!)],
  ["Total Deposited Amount:", ConversionUtility.toStringRupeesConversion(reportDetails.value?.totalDeposit!!)],
  ["Total Withdrawal:", ConversionUtility.toStringRupeesConversion(reportDetails.value?.totalWithdrawal!!)],
  ["New Savings Accounts:", reportDetails.value?.totalNewAccountsOpened],
  ["Closed Savings Accounts:", reportDetails.value?.totalSBAccountClosed],
  [],
];

const worksheet = XLSX.utils.aoa_to_sheet(reportData);
worksheet['!cols'] = [
  { wch: 32 },
  { wch: 25 }
];
const workbook = XLSX.utils.book_new();
XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

loadingProgress.value = 50;

// Generate Excel file as an array buffer
const workbookArrayBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

// Convert the array buffer to a Blob
const blob = new Blob([workbookArrayBuffer], { type: 'application/octet-stream' });
loadingProgress.value = 75;

// Create a download link and trigger the download
const url = window.URL.createObjectURL(blob);
const link = document.createElement('a');
link.href = url;
link.setAttribute('download', `Savings_Account_Report_${ConversionUtility.toDateFormat(reportDetails.value?.startDate!!)}_to_${ConversionUtility.toDateFormat(reportDetails.value?.endDate!!)}.xlsx`);
document.body.appendChild(link);
link.click();
}

watch(fromDate, (newFromDate) => {
  if (newFromDate && toDate.value) {
    toDate.value = null;
  }
});
</script>

<template>
    <Toast class="error-p-toast" />
    <main class="main">
      <Card class="search-card">
        <template #title>
          <h2 class="title-right text-3xl font-bold">Savings Accounts Reports</h2>
        </template>
        <template #content>
          <Divider/>

            <div class="form">
              <FloatLabel>
                <label for="fromDate" class="mandatory">From</label>
                <DatePicker v-model="fromDate" id="fromDate" class="input-text"  :min-date="minDate" :max-date="maxDate" dateFormat="dd/mm/yy"/>
              </FloatLabel>

              <FloatLabel>
                <label for="toDate" class="mandatory">To</label>
                <DatePicker v-model="toDate" id="toDate" class="input-text" :min-date="fromDate" :maxDate="maxDate" dateFormat="dd/mm/yy"/>
              </FloatLabel>
              <CustomButton title="View Reports" @click="viewReports" class="cstmbtn"/>
            </div>

          <Fieldset v-if="reportDetails" style="text-align: left; margin: 10px" legend="Savings Account report Details">

            <div class="cont">
              <div class="report-details">
                <div class="">
                  <span class="">Range from : </span>
                  <span class="text">{{ ConversionUtility.toDateFormat(reportDetails?.startDate) }} to {{ ConversionUtility.toDateFormat(reportDetails?.endDate)}}</span>
                </div>
                <div class="">
                  <span class="">Total Deposits: </span>
                  <span class="text">{{ConversionUtility.toStringRupeesConversion(reportDetails?.totalDeposit)}}</span>
                </div>
                <div class="">
                  <span class="">Total Withdrawals: </span>
                  <span class="text">{{ConversionUtility.toStringRupeesConversion(reportDetails?.totalWithdrawal)}}</span>
                </div>

                <div>
                  <span class="">New Savings Accounts: </span>
                  <span class="text">{{reportDetails?.totalNewAccountsOpened}}</span>
                </div> 
                <div>
                  <span class="">Closed Savings Accounts: </span>
                  <span class="text">{{reportDetails?.totalSBAccountClosed}}</span>
                </div>
                
              </div>
            </div>
            <div style="display: flex; justify-content: flex-end; margin-top: 20px;gap: 1rem">
              <CustomButton title="Download Report" @click="download"/>
            </div>
          </Fieldset>
          <div v-else class="container-footer">
              <Tag severity="secondary">No Savings Reports found for the given time.</Tag>
          </div>

          <div v-if="isLoading">
            <LoadingComponent
            text="Please wait while we process the request!"
            :dialogVisiblity="isLoading"
            />
          </div>
        </template>

        <template #footer>

        </template>
      </Card>
    </main>
</template>
<style>

.mandatory::after {
  content: ' *';
  color: red;
}
.form {
  margin: 2rem 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2.5rem;
}
.cstmbtn{
  width: 50%;
}
.input-text {
  height: 45px;
}
.report-details{
  gap: 2rem;
  display: grid;
  grid-template-columns:repeat(3, 1fr) ;
}
</style>
