<script setup lang="ts">
import SearchComponent from '@/components/SearchComponent.vue'
import NotFound from '@/assets/not_found.svg'
import { ref } from 'vue'
import type { ToastMessageOptions } from 'primevue/toast'
import { useToast } from 'primevue/usetoast'
import ApiService from '@/api/ApiService'
import Storage from '@/utils/Storage'
import router from '@/router'
import type { SearchCustomerRequest } from '@/api/models/customer/SearchCustomerRequest'
import type { Customer } from '@/api/models/customer/Customer'
import ConversionUtility from '@/utils/ConversionUtility'
import CustomButton from '@/components/CustomComponent/CustomButton.vue'
import type { CreatePigmyRequest } from '@/api/models/pigmy/CreatepigmyRequest'
import type { CreatedBy } from '@/api/models/CreatedBy'
import LoadingComponent from '@/components/LoadingComponent.vue'
import type { Pigmy } from '@/api/models/pigmy/Pigmy'
import type { GetPigmiesRequest } from '@/api/models/pigmy/GetPigmiesRequest'
import ValidationUtility from '@/utils/ValidationUtility'
import type { CustomerDetailsRequest } from '@/api/models/customer/CustomerDetailsRequest'

const customer = ref<Customer>()
const pigmyAmount = ref<number>()
const toast = useToast()
const isLoading = ref<boolean>(false)
const pigmies = ref<Pigmy[]>([])
const createdPigmy = ref<Pigmy>()
const searchQuery = ref<string>('')
const isPigmyCreated = ref<boolean>(false)
const pigmiesColumns = [
  { field: 'id', header: 'ID', colStyle: { width: '25%' } },
  { field: 'pigmyAmount', header: 'Pigmy Amount(₹)', colStyle: { width: '30%' } },
  { field: 'balance', header: 'Balance(₹)', colStyle: { width: '30%' } },
  { field: 'createdAt', header: 'Started ON:', colStyle: { width: '15%' } }
]

function handelSearch(id: string) {
  if (!ValidationUtility.validateNumber(id)) {
    customer.value = undefined
    pigmies.value = []
    showToast('Invalid Customer ID', 'Please provide a valid Customer ID!', 'error')
    return
  }
  searchQuery.value = id
  customer.value = undefined
  pigmyAmount.value = undefined
  pigmies.value = []
  if (!searchQuery.value) {
    showToast('Customer ID is empty', 'Validation failed!', 'error')
    return
  }
  if (!ValidationUtility.validateNumber(searchQuery.value)) {
    showToast('Enter a valid customer ID', 'Given input is not of type Number!', 'error')
    return
  }
  isLoading.value = true

  const searchCustomer: CustomerDetailsRequest = {
    cusId: searchQuery.value
  }
  const searchPigmies: GetPigmiesRequest = {
    cusId: searchQuery.value
  }
  ApiService.getCustomerDetails(searchCustomer)
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        customer.value = response.data.customer!!
        ApiService.getPigmies(searchPigmies)
          .then((response) => {
            setTimeout(() => {
              if (response.statusCode == 401) {
                Storage.clearData()
                router.push({
                  name: 'login'
                })
              }
            }, 2000)
            if (response.statusCode == 200) {
              pigmies.value = response.data
            }
          })
          .catch((error) => {
            showToast('Failed to fetch Pigmy', error, 'error')
          })
          .finally(() => {
            isLoading.value = false
          })
      } else {
        showToast('Failed to fetch Pigmy', response.error ?? 'Something went wrong', 'error')
      }
    })
    .catch((error) => {
      showToast('Failed to fetch Pigmy', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
    })
}
function handleCreatePigmy() {
  if (!pigmyAmount.value) {
    showToast('Pigmy Amount is blank/empty', 'Creating Pigmy failed!', 'error')
    return
  }
  if (pigmyAmount.value.toString().length > 7) {
    showToast('Pigmy Amount should be less than 8 digits!', 'Creating Pigmy failed!', 'error')
    return
  }

  isLoading.value = true
  const createBy: CreatedBy = {
    empId: Storage.getEmpId()!!,
    createdAt: Date.now()
  }
  const createPigmyRequest: CreatePigmyRequest = {
    cusId: Number(customer.value?.customerId),
    pigmyAmount: ConversionUtility.toPaiseConversion(pigmyAmount.value),
    balance: 0,
    createdAt: Date.now(),
    updatedAt: Date.now(),
    isClosed: null,
    closedAt: null,
    interestAmount: null,
    transferredSBId: null,
    penaltyAmount: null,
    closedBy: null,
    createdBy: createBy
  }

  ApiService.createPigmy(createPigmyRequest)
    .then((response) => {
      setTimeout(() => {
        if (response.statusCode == 401) {
          Storage.clearData()
          router.push({
            name: 'login'
          })
        }
      }, 2000)
      if (response.statusCode == 200) {
        createdPigmy.value = response.data
        isPigmyCreated.value = true
        // showToast(
        //   'Pigmy Created Successfully',
        //   'Pigmy with ID ' + response.data.id + ' created',
        //   'success'
        // )
        handelSearch(customer.value?.customerId!!)
      } else {
        showToast('Failed to create Pigmy', response.error, 'error')
      }
    })
    .catch((error) => {
      showToast('Failed to create Pigmy', error, 'error')
    })
    .finally(() => {
      isLoading.value = false
      pigmyAmount.value = undefined
    })
}

function handleDataTableProps(index: number, data: any): string {
  switch (index) {
    case 0: {
      return data.id
    }
    case 1: {
      return ConversionUtility.toStringRupeesConversion(data.pigmyAmount)
    }
    case 2: {
      return ConversionUtility.toStringRupeesConversion(data.balance)
    }
    case 3: {
      return ConversionUtility.toDateFormat(data.createdAt)
    }
    default: {
      return 'NA'
    }
  }
}

function showToast(summary: string, detail: string, severity: ToastMessageOptions['severity']) {
  toast.add({
    severity: severity,
    summary: summary,
    detail: detail,
    life: 5000
  })
}
</script>
<template>
  <main class="main">
    <Card class="sea rch-card">
      <template #content>
        <Dialog
          :visible="isPigmyCreated"
          modal
          header="Created Pigmy Successfully"
          :draggable="false"
          :closable="false"
        >
          <p>Pigmy ID: {{ createdPigmy?.id }}</p>
          <p>
            Pigmy Amount:
            {{ ConversionUtility.toStringRupeesConversion(createdPigmy?.pigmyAmount!!) }}
          </p>
          <CustomButton
            title="Close"
            icon="times"
            @click="
              () => {
                isPigmyCreated = false
                createdPigmy = undefined
              }
            "
          ></CustomButton>
        </Dialog>
        <Toast class="error-p-toast" />
        <SearchComponent
          title="Create Pigmy"
          subtitle="Create a pigmy for a existing customer. (Eg. - 100001)."
          placeholder="Enter the customer ID to create pigmy"
          @search-click="handelSearch"
        />
        <LoadingComponent
          text="Please wait while we process the request!"
          :dialogVisiblity="isLoading"
        />
        <div v-if="customer && !isLoading" class="cont">
          <div class="holder-details">
            <div>
              <span class="text-heading">Customer Id: </span>
              <span class="text">{{ customer.customerId }}<br /></span>
            </div>
            <div>
              <span class="text-heading">Name: </span>
              <span class="text"
                >{{ customer.firstName }} {{ customer.middleName ?? '' }} {{ customer.lastName
                }}<br
              /></span>
            </div>
            <div>
              <span class="text-heading">Phone Number: </span>
              <span class="text">{{ customer.phoneNumber }}<br /></span>
            </div>
            <div>
              <span class="text-heading">City/Town: </span>
              <span class="text">{{ customer.city }}<br /></span>
            </div>
          </div>
          <div class="operations">
            <div class="button-transactions">
              <InputNumber
                v-model="pigmyAmount"
                b
                class="input-text"
                placeholder="Pigmy Amount"
                mode="currency"
                currency="INR"
                :min-fraction-digits="0"
                locale="en-US"
                fluid
                :min="0"
              />
            </div>
            <CustomButton icon="plus" title="Create Pigmy" @click="handleCreatePigmy" />
          </div>
          <h2 class="title text-3xl font-bold">Linked Pigmy Accounts :</h2>
          <div v-if="pigmies.length > 0 && !isLoading" class="cont">
            <DataTable :value="pigmies" paginator :rows="10" :rowsPerPageOptions="[10, 20, 50]">
              <Column
                v-for="(col, index) in pigmiesColumns"
                :key="col.field"
                :field="col.field"
                :header="col.header"
                :style="col.colStyle"
              >
                <template #body="slotProps">
                  {{ handleDataTableProps(index, slotProps.data) }}
                </template>
              </Column>
            </DataTable>
          </div>
          <div v-else>
            <Tag severity="secondary">No Pigmy Linked to this customer.</Tag>
          </div>
        </div>
        <div v-if="!customer && !isLoading" class="notFound">
          <Image :src="NotFound" width="800" />
          <Chip label="No pigmy found for the provided search query!" style="font-weight: 600" />
        </div>
      </template>
    </Card>
  </main>
</template>
<style scoped>
.title {
  text-align: start;
  margin: 1.5rem 0 0 1.5rem;
}
</style>
